<template>
  <div class="customer currPage">
    <!-- 新增客户头部模块 -->
    <div class="tip">
      <!-- <el-row :gutter="20">
        <el-col style="width: 20%;">
          <div class="grid-content flex justify-between">
            <div>
              <div> <span>全部</span></div>
              <div class="pt10 pb10">{{ AccessInfo.totalNum }}</div>
            </div>
            <div><i class="el-icon-menu" style="background-color: #68d4ff"></i></div>
          </div>
        </el-col>
        <el-col style="width: 20%;">
          <div class="grid-content flex justify-between">
            <div>
              <div> <span>勘探</span></div>
              <div class="pt10 pb10">{{ AccessInfo.explorateNum }}</div>
            </div>
            <div><i class="el-icon-s-custom" style="background-color: #f56c6c"></i></div>
          </div>
        </el-col>
        <el-col style="width: 20%;">
          <div class="grid-content flex justify-between">
            <div>
              <div> <span>安装</span></div>
              <div class="pt10 pb10">{{ AccessInfo.installNum }}</div>
            </div>
            <div><i class="el-icon-document-checked" style="background-color: #7d9ffd"></i></div>
          </div>
        </el-col>
        <el-col style="width: 20%;">
          <div class="grid-content flex justify-between">
            <div>
              <div> <span>井网</span></div>
              <div class="pt10 pb10">{{ AccessInfo.bwNum }}</div>
            </div>
            <div><i class="el-icon-s-platform" style="background-color: #bb8df1ff"></i></div>
          </div>
        </el-col>
        <el-col style="width: 20%;">
          <div class="grid-content flex justify-between">
            <div>
              <div> <span>超期预警</span></div>
              <div class="pt10 pb10">{{ AccessInfo.warnNum }}</div>
            </div>
            <div><i class="el-icon-coordinate" style="background-color: #E6A23C"></i></div>
          </div>
        </el-col>
      </el-row> -->
    </div>

    <!-- <div class="topButton">
      <el-button class="item">首页</el-button>
      <el-button class="item" type="primary">客户列表</el-button>
    </div> -->
    <div class="center-box box-padding">
      <el-card class="form-card smallpadding">
        <el-form class="formSize" style="display: flex; justify-content: space-between" label-width="80px">
          <el-row>
            <el-col :span="4"><el-form-item label="归属公司"><el-select clearable style="width: 100%"
                  @change="remamberValue" filterable v-model="queryInfo.condition.agentCode" placeholder="请选择">
                  <el-option :label="item.agentName" :value="item.agentCode" v-for="(item, index) in companyOptions"
                    :key="index"></el-option> </el-select></el-form-item>
            </el-col>
            <el-col :span="4"><el-form-item label="订单进度">
                <el-select class="small-height-input" style="width: 100%" v-model="queryInfo.condition.orderSchedule">
                  <el-option label="待提交审核" value="待提交审核"></el-option>
                  <el-option label="待资料审核" value="待资料审核"></el-option>
                  <el-option label="待发货申请" value="待发货申请"></el-option>
                  <el-option label="待发货调度" value="待发货调度"></el-option>
                  <el-option label="待安装完工" value="待安装完工"></el-option>
                  <el-option label="待验收派单" value="待验收派单"></el-option>
                  <el-option label="待现场验收" value="待现场验收"></el-option>
                  <el-option label="待消缺整改" value="待消缺整改"></el-option>
                  <el-option label="已完工验收" value="已完工验收"></el-option> </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4"><el-form-item label="套餐系列"><el-input placeholder="请输入"></el-input></el-form-item>
            </el-col>
            <el-col :span="4"><el-form-item label="客户姓名"><el-input v-model="queryInfo.condition.customerName"
                  placeholder="请输入"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="锁定类型"><el-select v-model="select" placeholder="请选择"></el-select></el-form-item>
            </el-col>
            <!-- line2 -->
            <el-col :span="4"><el-form-item label="备案模式"><el-input placeholder="请输入"></el-input></el-form-item>
            </el-col>
            <el-col :span="4"><el-form-item label="关联订单号"><el-input v-model="queryInfo.condition.orderCode"
                  placeholder="请输入"></el-input></el-form-item>
            </el-col>
            <el-col :span="4"><el-form-item label="安装方式"><el-input v-model="queryInfo.condition.installType"
                  placeholder="请输入"></el-input></el-form-item>
            </el-col>
            <el-col :span="4"><el-form-item label="订单编号"><el-input v-model="queryInfo.condition.customerNumber"
                  placeholder="请输入"></el-input></el-form-item>
            </el-col>
            <el-col :span="5">
              <el-button style="margin-left: 12px" icon="el-icon-search" type="primary" @click="getTable">查询</el-button>
              <el-button @click="resetCondition" icon="el-icon-refresh">重置</el-button>
              <el-button @click="back" icon="el-icon-refresh">返回</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </div>
    <div class="bottom-box box-padding">
      <el-card class="diy-padding">
        <div class="center-head">
          <div class="left">
            <el-button class="small-padding" @click="distribution">分配</el-button>
            <el-button class="small-padding">导入客户</el-button>
          </div>
          <div class="right">
            <!-- <el-button @click="addCustomer" icon="el-icon-plus" class="small-padding">新增客户</el-button> -->
          </div>
        </div>
        <div class="center-table">
          <el-table @row-dblclick="showDetail" @selection-change="handleSelectionChange" class="fontSize-table"
            :row-style="{ height: '20px' }" :cell-style="{ padding: '0px' }" header-row-class-name="table-head-row"
            :data="tableData" v-loading="tableLoading" border height="calc(100vh - 300px)"
            style="width: 100%; margin-top: 7px">
            <el-table-column type="selection" align="center" width="45">
            </el-table-column>
            <el-table-column class-name="cellNopadding" align="center" prop="address" width="50px" fixed="left"
              label="操作">
              <template slot-scope="{ row }">
                <el-button class="btn2" @click="showLogs(row)">日志</el-button>
                <!-- <el-button class="btn1" style="margin: 0 2px;" @click="toConstruction">施工派单</el-button>
                <el-button class="btn1" @click="showStep2(row)">物料</el-button>
                <el-button class="btn2" @click="baoZhuang(row)">报装</el-button>
                <el-button class="btn3" @click="bingWang(row)">并网</el-button> -->
              </template>
            </el-table-column>
            <el-table-column prop="agentName" label="归属公司">
            </el-table-column>
            <el-table-column min-width="118px" label="订单号" prop="customerNumber">
            </el-table-column>
            <el-table-column show-overflow-tooltip width="60px" prop="customerName" label="客户名称">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="address" min-width="180px" label="安装地址">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="projectCompanyName" min-width="150px" label="项目公司">
            </el-table-column>
            <el-table-column show-overflow-tooltip align="center" min-width="100px" width="100px" prop="capacity"
              class-name="row-padding" label="装机容量(KW)">
            </el-table-column>
            <el-table-column show-overflow-tooltip align="center" max-width="100px" width="100px"
              class-name="row-padding" prop="moudleNum" label="组件数量(片)">
            </el-table-column>
            <el-table-column show-overflow-tooltip align="center" max-width="100px" width="100px"
              class-name="row-padding" prop="moudleType" label="组件类型">
            </el-table-column>
            <!-- 订单进度 -->
            <el-table-column show-overflow-tooltip align="center" max-width="100px" width="100px"
              class-name="row-padding" prop="orderSchedule" label="订单进度">
              <template slot-scope="{row}">
                <el-button v-show="row.orderSchedule" :class="getColor(row.orderSchedule)">{{ row.orderSchedule
                  }}</el-button>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip align="center" max-width="100px" width="100px"
              class-name="row-padding" prop="sellName" label="销售姓名">
            </el-table-column>
            <el-table-column show-overflow-tooltip align="center" max-width="100px" width="100px"
              class-name="row-padding" prop="orderStatus" label="订单状态">
            </el-table-column>
            <el-table-column align="center" max-width="80px" width="80px" prop="isBz" class-name="overflowTip"
              label=" 报装">
              <template slot-scope="{ row }">
                <el-tag type="success" v-if="row.isBz == 1">已报装</el-tag>
                <el-tag type="danger" v-else>未报装</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" max-width="80px" width="80px" class-name="overflowTip" prop="isBw"
              label="并网">
              <template slot-scope="{ row }">
                <el-tag type="success" v-if="row.isBw == 1">已并网</el-tag>
                <el-tag type="danger" v-else>未并网</el-tag>
              </template>
            </el-table-column>
            <el-table-column min-width="150px" show-overflow-tooltip align="center" prop="createDate" label="录入时间">
            </el-table-column>
            <el-table-column max-width="60px" width="60px" show-overflow-tooltip align="center" prop="createName"
              label="录入人">
            </el-table-column>

            <!-- <el-table-column
              show-overflow-tooltip
              align="center"
              prop="source"
              label="客户来源"
            >
            </el-table-column> -->

            <!-- <el-table-column
              show-overflow-tooltip
              align="center"
              prop="install"
              label="地址"
            >
            </el-table-column> -->
            <!-- <el-table-column
              show-overflow-tooltip
              align="center"
              prop="address"
              label="回访记录"
            >
            </el-table-column> -->

            <!-- <el-table-column prop="legalType" show-overflow-tooltip align="center" label="证件类型"> </el-table-column>
            <el-table-column show-overflow-tooltip align="center" prop="idCard" label="证件号">
            </el-table-column>
            <el-table-column show-overflow-tooltip width="60px" align="center" label="合伙人"> </el-table-column>
            <el-table-column
              min-width="100px"
              show-overflow-tooltip
              align="center"
              label="代理商名称"
            >
            </el-table-column>
            <el-table-column show-overflow-tooltip align="center" label="负责时间"> </el-table-column> -->

            <!-- <el-table-column
              min-width="100px"
              prop="address"
              label="总部负责人"
              show-overflow-tooltip
            >
            </el-table-column> -->
            <!-- <el-table-column
              min-width="140px"
              align="center"
              label="总部负责时间"
            >
            </el-table-column>
            <el-table-column
              min-width="100px"
              align="center"
              show-overflow-tooltip
              label="分公司负责人"
            >
            </el-table-column>
            <el-table-column
              align="center"
              show-overflow-tooltip
              label="分公司负责时间"
              min-width="140px"
            >
            </el-table-column> -->
            <el-table-column show-overflow-tooltip align="center" prop="remark" label="备注">
            </el-table-column>
            <el-table-column show-overflow-tooltip align="center" prop="updateName" label="更新人">
            </el-table-column>
            <el-table-column show-overflow-tooltip align="center" prop="updateDate" label="更新时间">
            </el-table-column>
          </el-table>

          <div class="flex" style="justify-content: flex-end">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 500]" :page-size="queryInfo.pageSize"
              layout="total, prev, pager, next,sizes" :total="total">
            </el-pagination>
          </div>
        </div>
      </el-card>
    </div>
    <el-drawer :modal-append-to-body="false" size="calc(100% - 390px)" :append-to-body="false" :visible.sync="drawer"
      :with-header="false">
      <div class="drawer-main">
        <div class="drawer-head" style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <span>客户详情</span>
          <i @click="drawer = false" class="el-icon-close drawer-close"></i>
        </div>
        <div class="drawer-bottom flex">
          <div class="drawer-bottom-left">
            <div class="top-info">
              <el-row class="top-row">
                <el-col :span="8"><span class="label">姓名</span><span class="value">{{
                  customerInfoForm.baseInfo.customerName
                }}</span></el-col>
                <el-col :span="8"><span class="label">手机号</span><span class="value">{{
                  customerInfoForm.baseInfo.mobile
                }}</span></el-col>
                <el-col :span="8" class="flex align-center"><span class="label">合作模式</span>
                  <span class="value">{{
                    customerInfoForm.baseInfo.collaborationMode
                  }}</span>
                </el-col>
                <!-- 用户标签 -->
                <el-col :span="8" class="flex align-center" style="margin-top: 10px;"><span class="label">安装方式</span>
                  <span class="value">{{
                    customerInfoForm.baseInfo.installType
                  }}</span>
                </el-col>
                <!-- 客户来源 -->
                <el-col :span="8" class="flex align-center" style="margin-top: 10px;"><span class="label">客户类型</span>
                  <span class="value">{{ customerInfoForm.baseInfo.legalType }}</span>
                </el-col>
                <el-col :span="8" class="flex align-center" style="margin-top: 10px;"><span class="label">代理商名称</span>
                  <span class="value">{{ customerInfoForm.baseInfo.agentName }}</span>
                </el-col>

                <!-- 所属分公司 -->
                <el-col :span="8" class="flex align-center" style="margin-top: 10px;"><span class="label">所在城市</span>
                  <span class="value">{{ customerInfoForm.baseInfo.cityName }}</span>
                </el-col>
                <el-col :span="8" class="flex align-center" style="margin-top: 10px;"><span class="label">地址</span>
                  <span class="value">{{ customerInfoForm.baseInfo.companyAddress }}</span>
                </el-col>
              </el-row>
              <!-- 底部tab -->
              <div class="bottom-tab flex" style="align-items: center">
                <div style="width: 90%">
                  <el-tabs v-model="activeName" @tab-click="handleClick(activeName)">
                    <!-- <el-tab-pane label="全部" name="first"></el-tab-pane> -->
                    <el-tab-pane label="基本信息" name="second"></el-tab-pane>
                    <el-tab-pane label="商务资料" name="third"></el-tab-pane>
                    <el-tab-pane label="商务勘探" name="fourth"></el-tab-pane>
                    <el-tab-pane label="签约" name="fifth"></el-tab-pane>
                    <el-tab-pane label="验收" name="sixth"></el-tab-pane>
                    <el-tab-pane label="审核记录" name="seventh"></el-tab-pane>
                  </el-tabs>
                </div>
                <div style="margin-bottom: 7px">
                  <!-- <el-button @click="saveBaseInfo('baseInfo')" type="primary">保存</el-button> -->
                </div>
              </div>
            </div>
            <div class="bottom-info" v-if="activeName == 'second'">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <div style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    ">
                    <span>基本信息</span>
                    <el-button @click="saveBaseInfo('baseInfo')" type="primary">保存</el-button>
                  </div>
                </div>
                <div class="basicInfoBox">
                  <div class="basicInfoItem">
                    <div>姓名:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.customerName"></el-input>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>手机号:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.mobile"></el-input>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>客户编号:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.customerCode" placeholder="请输入" />
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>性别:</div>
                    <div>
                      <el-select v-model="customerInfoForm.baseInfo.sex" placeholder="请选择" style="width: 100%">
                        <el-option label="男" :value="1"></el-option>
                        <el-option label="女" :value="2"></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>年龄:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.age">
                      </el-input>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>微信:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.wxNo" placeholder="请输入" />
                    </div>
                  </div>

                </div>
                <div class="flex" style="margin-top: 7px">
                  <div style="width: 168px; text-align: right; font-size: 14px">
                    备注:
                  </div>
                  <div style="margin-left: 10px; width: calc(100% - 90px)">
                    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入内容"
                      v-model="customerInfoForm.baseInfo.remark" />
                  </div>
                </div>
              </el-card>
              <el-card class="box-card detail-card" style="margin-top: 10px">
                <div slot="header" class="clearfix">
                  <span>详细信息:</span>
                </div>
                <div class="basicInfoBox">
                  <div class="basicInfoItem">
                    <div>客户昵称:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.nickname" placeholder="请输入" />
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>所在城市:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.cityName" placeholder="请选择" style="width: 100%">
                      </el-input>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>邮箱:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.eMail" placeholder="请选择" style="width: 100%">
                      </el-input>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>性别:</div>
                    <el-select v-model="customerInfoForm.baseInfo.sex" placeholder="请选择" style="width: 100%">
                      <el-option label="男" :value="1"></el-option>
                      <el-option label="女" :value="2"></el-option>
                    </el-select>
                  </div>
                  <div class="basicInfoItem">
                    <div>身份证号:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.certificateType" placeholder="请选择"
                        style="width: 100%">
                      </el-input>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>合伙人:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.partner" placeholder="请选择" style="width: 100%">
                      </el-input>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>分公司负责人:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.companyManager" placeholder="请选择"
                        style="width: 100%">
                      </el-input>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>总部负责人:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.headManager" placeholder="请选择" style="width: 100%">
                      </el-input>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>创建人:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.createName" placeholder="请选择" style="width: 100%">
                      </el-input>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>创建时间:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.createDate" placeholder="请选择" style="width: 100%">
                      </el-input>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>详细地址:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.address" placeholder="请输入" />
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>关联订单号:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.orderCode" placeholder="请输入" />
                    </div>
                  </div>
                </div>
              </el-card>
              <el-card class="box-card detail-card" style="margin-top: 10px">
                <div slot="header" class="clearfix">
                  <span>证照信息:</span>
                </div>
                <div class="basicInfoBox">
                  <div class="basicInfoItem">
                    <div>证件信息:</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelCertificateImg1">
                        <div v-if="certificateImg1List.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in certificateImg1List"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete"
                                @click.stop="handelRemoveSceneSouthfacade(item, 'certificateImg1List')"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="imgText">*证件人像面</div>
                    </div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelCertificateImg2">
                        <div v-if="certificateImg2List.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in certificateImg2List"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete"
                                @click.stop="handelRemoveSceneSouthfacade(item, 'certificateImg2List')"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="imgText">*证件国徽面</div>
                    </div>
                    <div></div>
                  </div>
                  <div class="basicInfoItem"  v-if="indexRow ? indexRow.customerType == 3 : ''" >
                    <div>对公账户:</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelCorporateAccount">
                        <div v-if="corporateAccountList.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in corporateAccountList"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete"
                                @click.stop="handelRemoveSceneSouthfacade(item, 'corporateAccountList')"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <!-- <div class="imgText">这两个只有安装模式在勾选公共屋顶时才会出现</div> -->
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>开户行联行号:</div>
                    <div>
                      <el-input v-model="customerInfoForm.baseInfo.bankcardNo" placeholder="请输入" />
                    </div>
                  </div>
                  <div class="basicInfoItem" v-if="indexRow ? indexRow.customerType == 3 : ''">
                    <div>营业执照照片:</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelBusinessLicense">
                        <div v-if="businessLicenseList.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in businessLicenseList"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete"
                                @click.stop="handelRemoveSceneSouthfacade(item, 'businessLicenseList')"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>银行卡正面:</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelBankcardImg1">
                        <div v-if="bankcardImg1List.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in bankcardImg1List"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete"
                                @click.stop="handelRemoveSceneSouthfacade(item, 'bankcardImg1List')"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="imgText">*银行卡正面</div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </el-card>
            </div>
            <div class="bottom-info" v-if="activeName == 'third'">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <div style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;">
                    <span>商务资料</span>
                    <el-button @click="saveBusinessInfo()" type="primary">保存</el-button>
                  </div>
                </div>
                <div class="basicInfoBox">
                  <div class="basicInfoItem">
                    <div>出租方营业执照:(社会统一信用代码证书)</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelBusinessLicense2">
                        <div v-if="businessLicenseList2.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in businessLicenseList2"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete"
                                @click.stop="handelRemoveSceneSouthfacade(item, 'businessLicenseList2')"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>对公账户信息(盖公章):</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelCorporateAccount2">
                        <div v-if="corporateAccountList2.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in corporateAccountList2"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete"
                                @click.stop="handelRemoveSceneSouthfacade(item, corporateAccountList2)"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>附属机构《营业执照》:</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelOrganLicense">
                        <div v-if="organLicenseList.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in organLicenseList"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete"
                                @click.stop="handelRemoveSceneSouthfacade(item, 'organLicenseList')"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>并网申请表:</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelBwApply">
                        <div v-if="bwApplyList.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in bwApplyList" :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete"
                                @click.stop="handelRemoveSceneSouthfacade(item, 'bwApplyList')"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>房屋产权证明:</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelPropertyCertificate">
                        <div v-if="propertyCertificateList.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in propertyCertificateList"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete"
                                @click.stop="handelRemoveSceneSouthfacade(item, 'propertyCertificateList')"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>特殊安装声明:</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelInstallationStatement">
                        <div v-if="installationStatementList.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in installationStatementList"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete"
                                @click.stop="handelRemoveSceneSouthfacade(item, 'installationStatementList')"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </div>
                  </div>
                  <div class="basicInfoItem">
                    <div>产权承诺书:</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelTitleCommitment">
                        <div v-if="titleCommitmentList.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in titleCommitmentList"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete"
                                @click.stop="handelRemoveSceneSouthfacade(item, 'titleCommitmentList')"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </el-card>
            </div>
            <div class="bottom-info" v-if="activeName == 'fourth'">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>商务勘探</span>
                </div>
                <div class="basicInfoBox1">
                  <div class="basicInfoItem" style="width: 100%;">
                    <div>定位</div>
                    <div>
                      {{ explorationObj.location }}
                    </div>
                  </div>
                  <div></div>
                  <div>房屋照片信息</div>
                  <div></div>
                  <div class="basicInfoItem" style="display: flex; margin-bottom: 10px;">
                    <div class="basicTitleItem"
                      style="width: 80px; align-items: center; margin-top: 30px; display: flex;font-size: 14px;">
                      正大门照片:</div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in mainGateImgList" :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*正大门</div>
                      </div>
                    </div>
                  </div>
                  <div class="basicInfoItem" style="display: flex;margin-bottom: 10px;">
                    <div class="basicTitleItem"
                      style="width: 80px; align-items: center; margin-top: 40px; display: flex;font-size: 14px;">
                      主房房屋全景照片:</div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in fullViewImgList" :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*房屋全景</div>
                      </div>
                    </div>
                  </div>
                  <div class="basicInfoItem" style="display: flex;margin-bottom: 10px;">
                    <div class="basicTitleItem"
                      style="width: 80px; align-items: center; margin-top: 40px; display: flex;font-size: 14px;">
                      主房屋顶照片:</div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in mainRoofImgList" :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*屋顶</div>
                      </div>
                    </div>
                  </div>
                  <div class="basicInfoItem" style="display: flex;margin-bottom: 10px;">
                    <div class="basicTitleItem"
                      style="width: 80px; align-items: center; margin-top: 40px; display: flex;font-size: 14px;">
                      现场照片:</div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSoutheastImgList"
                        :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*东面</div>
                      </div>
                    </div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSoutheastImgList"
                        :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*东南面</div>
                      </div>
                    </div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthImgList" :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*南面</div>
                      </div>
                    </div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthwestImgList"
                        :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*西南面</div>
                      </div>
                    </div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneWestImgList" :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*西面</div>
                      </div>
                    </div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacadeImgList"
                        :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*房屋南立面</div>
                      </div>
                    </div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneNorthfacadeImgList"
                        :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*房屋北立面</div>
                      </div>
                    </div>
                  </div>
                  <div class="basicInfoItem" style="display: flex;margin-bottom: 10px;">
                    <div class="basicTitleItem"
                      style="width: 80px; align-items: center; margin-top: 40px; display: flex;font-size: 14px;">
                    </div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneNorthfacadeImgList"
                        :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*房屋内部结构照片</div>
                      </div>
                    </div>
                  </div>
                  <div class="basicInfoItem" style="display: flex;margin-bottom: 10px;">
                    <div class="basicTitleItem"
                      style="width: 80px; align-items: center; margin-top: 40px; display: flex;font-size: 14px;">
                      现场视屏:</div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneVideoList" :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*周围环境及屋面</div>
                      </div>
                    </div>
                  </div>
                  <div class="basicInfoItem" style="display: flex;margin-bottom: 10px;">
                    <div class="basicTitleItem"
                      style="width: 80px; align-items: center; margin-top: 40px; display: flex;font-size: 14px;">
                      其他照片:</div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in azimuthImgList" :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0">*方位角</div>
                      </div>
                    </div>
                    <div style="display: flex;">
                      <div style="margin: 5px" class="imgBox" v-for="(item, index) in installImgList" :key="index">
                        <img :src="item.explorateImgUrl" class="avatar" alt="" />
                        <div class="imgText" v-if="index == 0" style="color: #ccc; font-size: 0.7rem;">逆变器设计安装位置</div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="basicInfoItem" style="display: flex;">
                    <div style="width: 80px; align-items: center; margin-top: -40px; display: flex;">主房房屋全景照片:</div>
                    <div style="display: flex;">
                      <div>
                        <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                          :on-success="handelSceneSouthfacade">
                          <div v-if="sceneSouthfacade.length > 0" class="flex">
                            <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                              :key="index">
                              <img :src="item.explorateImgUrl" class="avatar" alt="" />
                              <div class="img-icon">
                                <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                                <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                              </div>
                            </div>
                          </div>
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="imgText">*房屋全景</div>
                      </div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelSceneSouthfacade">
                        <div v-if="sceneSouthfacade.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload><el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelSceneSouthfacade">
                        <div v-if="sceneSouthfacade.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload><el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelSceneSouthfacade">
                        <div v-if="sceneSouthfacade.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </div>
                  </div>
                  <div class="basicInfoItem" style="display: flex;">
                    <div style="width: 80px; align-items: center; margin-top: -40px; display: flex;">主房屋顶照片:</div>
                    <div style="display: flex;">
                      <div>
                        <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                          :on-success="handelSceneSouthfacade">
                          <div v-if="sceneSouthfacade.length > 0" class="flex">
                            <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                              :key="index">
                              <img :src="item.explorateImgUrl" class="avatar" alt="" />
                              <div class="img-icon">
                                <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                                <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                              </div>
                            </div>
                          </div>
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="imgText">*屋顶</div>
                      </div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelSceneSouthfacade">
                        <div v-if="sceneSouthfacade.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload><el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelSceneSouthfacade">
                        <div v-if="sceneSouthfacade.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload><el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelSceneSouthfacade">
                        <div v-if="sceneSouthfacade.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </div>
                  </div>
                  <div class="basicInfoItem" style="display: flex;">
                    <div style="width: 80px; align-items: center; margin-top: -40px; display: flex;">现场照片:</div>
                    <div style="display: flex; overflow: auto;">
                      <div>
                        <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                          :on-success="handelSceneSouthfacade">
                          <div v-if="sceneSouthfacade.length > 0" class="flex">
                            <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                              :key="index">
                              <img :src="item.explorateImgUrl" class="avatar" alt="" />
                              <div class="img-icon">
                                <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                                <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                              </div>
                            </div>
                          </div>
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="imgText">*东面</div>
                      </div>
                      <div>
                        <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                          :on-success="handelSceneSouthfacade">
                          <div v-if="sceneSouthfacade.length > 0" class="flex">
                            <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                              :key="index">
                              <img :src="item.explorateImgUrl" class="avatar" alt="" />
                              <div class="img-icon">
                                <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                                <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                              </div>
                            </div>
                          </div>
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="imgText">*东南面</div>
                      </div>
                      <div>
                        <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                          :on-success="handelSceneSouthfacade">
                          <div v-if="sceneSouthfacade.length > 0" class="flex">
                            <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                              :key="index">
                              <img :src="item.explorateImgUrl" class="avatar" alt="" />
                              <div class="img-icon">
                                <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                                <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                              </div>
                            </div>
                          </div>
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="imgText">*南面</div>
                      </div>
                      <div>
                        <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                          :on-success="handelSceneSouthfacade">
                          <div v-if="sceneSouthfacade.length > 0" class="flex">
                            <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                              :key="index">
                              <img :src="item.explorateImgUrl" class="avatar" alt="" />
                              <div class="img-icon">
                                <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                                <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                              </div>
                            </div>
                          </div>
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="imgText">*西南面</div>
                      </div>
                      <div>
                        <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                          :on-success="handelSceneSouthfacade">
                          <div v-if="sceneSouthfacade.length > 0" class="flex">
                            <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                              :key="index">
                              <img :src="item.explorateImgUrl" class="avatar" alt="" />
                              <div class="img-icon">
                                <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                                <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                              </div>
                            </div>
                          </div>
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="imgText">*西面</div>
                      </div>
                      <div>
                        <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                          :on-success="handelSceneSouthfacade">
                          <div v-if="sceneSouthfacade.length > 0" class="flex">
                            <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                              :key="index">
                              <img :src="item.explorateImgUrl" class="avatar" alt="" />
                              <div class="img-icon">
                                <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                                <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                              </div>
                            </div>
                          </div>
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="imgText">*房屋南立面</div>
                      </div>
                    </div>
                  </div>
                  <div class="basicInfoItem" style="display: flex;">
                    <div style="width: 80px;"></div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelSceneSouthfacade">
                        <div v-if="sceneSouthfacade.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="imgText">*房屋内部结构照片</div>
                    </div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelSceneSouthfacade">
                        <div v-if="sceneSouthfacade.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="imgText">*房屋北立面</div>
                    </div>
                  </div>
                  <div class="basicInfoItem" style="display: flex;">
                    <div style="width: 80px; align-items: center; margin-top: -40px; display: flex;">现场视屏:</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelSceneSouthfacade">
                        <div v-if="sceneSouthfacade.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="imgText">*周围环境及屋面</div>
                    </div>
                  </div>
                  <div class="basicInfoItem" style="display: flex;">
                    <div style="width: 80px; align-items: center; margin-top: -40px; display: flex;">其他照片:</div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelSceneSouthfacade">
                        <div v-if="sceneSouthfacade.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="imgText">*方位角</div>
                    </div>
                    <div>
                      <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                        :on-success="handelSceneSouthfacade">
                        <div v-if="sceneSouthfacade.length > 0" class="flex">
                          <div style="margin: 5px" class="imgBox" v-for="(item, index) in sceneSouthfacade"
                            :key="index">
                            <img :src="item.explorateImgUrl" class="avatar" alt="" />
                            <div class="img-icon">
                              <i class="el-icon-zoom-in" @click.stop="handelImage(item)"></i>
                              <i class="el-icon-delete" @click.stop="handelRemoveSceneSouthfacade(item)"></i>
                            </div>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="imgText" style="color: #CCCCCC;">逆变器设计安装位置</div>
                    </div>
                  </div> -->

                </div>
              </el-card>
            </div>

            <div class="bottom-info" v-if="activeName == 'fifth'">
              <el-card class="box-card">
                <div class="qy_tit">
                  <img style="width: 10px;height: 20px;" src="../../assets/images/normal_u10.svg" alt="">
                  <div>签约详情</div>
                </div>
                <div class="qy_Box">
                  <div class="qy_Boxli">
                    <div class="lis">
                      <div class="lis_one">合同标题：</div>
                      <div class="lis_two">{{ signdetail.contractTitle }}</div>
                    </div>
                    <div class="lis">
                      <div class="lis_one">签署场景：</div>
                      <div class="lis_two">{{ signdetail.signatureScene }}</div>
                    </div>
                  </div>
                  <div class="qy_Boxli">
                    <div class="lis">
                      <div class="lis_one">发起时间：</div>
                      <div class="lis_two">{{ signdetail.initiateDate }}</div>
                    </div>
                    <div class="lis">
                      <div class="lis_one">签署时间：</div>
                      <div class="lis_two">{{ signdetail.signDate }}</div>
                    </div>
                  </div>
                  <div class="qy_Boxli">
                    <div class="lis">
                      <div class="lis_one">合同发送状态：</div>
                      <div class="lis_two">{{ signdetail.status == 0 ? '未发送' : '已发送' }}</div>
                    </div>
                    <div class="lis">
                      <div class="lis_one">合同编号：</div>
                      <div class="lis_two">{{ signdetail.contractCode }}</div>
                    </div>
                  </div>
                  <div class="qy_Boxli">
                    <div class="lis">
                      <div class="lis_one">合同附件：</div>
                      <div class="lis_two" style="display: flex;align-items: center;">
                        <img style="width: 90px;height: 100px;" src="../../assets/images/PDF.png" alt="">
                        <div style="color: red;font-size: 13px;margin-left: 15px;">点击查看</div>
                      </div>
                    </div>
                    <div class="lis"></div>

                  </div>
                  <div class="send">发送合同</div>



                </div>
              </el-card>
            </div>
            <div class="bottom-info" v-if="activeName == 'sixth'">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <div class="bottom-tab">
                    <el-tabs v-model="bzActiveName" @tab-click="bzBandleClick">
                      <el-tab-pane label="报装信息" name="first"> </el-tab-pane>
                      <el-tab-pane label="物料信息" name="second"></el-tab-pane>
                    </el-tabs>
                  </div>
                </div>
                <div class="onGridInfoBox" v-show="bzActiveName == 'first'">
                  <div class="onGridInfoItem">
                    <div>1、安装区域细节照片：</div>
                    <div class="onGridImgBox">
                      <img v-for="(item, i) in onGridInfo.fvdht" :src="item" :key="i" alt="" class="onGridImgItem"
                        @click="toShowImg(item)" />
                    </div>
                  </div>
                  <div class="onGridInfoItem">
                    <div>2、施工安全技术交底作业单：</div>
                    <div class="onGridImgBox">
                      <img v-for="(item, i) in onGridInfo.fvdht" :src="item" :key="i" alt="" class="onGridImgItem"
                        @click="toShowImg(item)" />
                    </div>
                  </div>
                  <div class="onGridInfoItem">
                    <div>3、施工组长出工照：</div>
                    <div class="onGridImgBox">
                      <img v-for="(item, i) in onGridInfo.fvdht" :src="item" :key="i" alt="" class="onGridImgItem"
                        @click="toShowImg(item)" />
                    </div>
                  </div>
                </div>
              </el-card>
            </div>
            <div class="bottom-info" v-if="activeName == 'seventh'">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <div class="bottom-tab">
                    历史记录
                  </div>
                </div>
                <div class="timex" style="margin-left: 10px;height: calc(100vh - 200px);overflow: auto;">
                  <el-steps class="teps" reverse="false" finish-status="success" align-center direction="vertical"
                    :rtl="true">
                    <el-step>
                      <template slot="description">
                        <div style="height: 50px;color: #333333;">流程开始</div>
                      </template>
                    </el-step>
                    <el-step v-for="(act, index) in rightdata" :key="index">

                      <template slot="description">
                        <div class="shjl">
                          <div style="color: #d6aa7f;font-size: 12px;margin-bottom: 8px;">{{ act.businesscreateDate }}
                          </div>
                          <div v-show="act.bussinessProcessList.length > 0">商务审核人: <span style="color: #409eff;">{{
                            act.businesscreateName }}</span></div>
                          <div v-for="(e, h) in act.bussinessProcessList">
                            <div>
                              {{ e.processName }} 审核结果:
                              <span style="color: #63a103;" :class="e.processResult == 1 ? '' : 'spnacitive'">【{{
                                e.processResult == 1 ? "通过" : "不通过" }}】</span>
                            </div>
                            <div v-for="(x, c) in e.commentList">
                              <div v-if="x.comment">审核意见： {{ x.comment }}</div>
                              <img style="width: 50px;height: 50px;" v-for="(d, f) in x.commentImgList"
                                :src="d.commentImgUrl" v-if="d.commentImgUrl" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="shjl">
                          <div style="color: #d6aa7f;font-size: 12px;margin-bottom: 8px;">{{ act.techcreateDate }}</div>
                          <div v-show="act.techProcessList.length > 0">技术审核人: <span style="color: #409eff;">{{
                            act.techcreateName }}</span></div>
                          <div v-for="(e, h) in act.techProcessList">
                            <div>
                              {{ e.processName }} 审核结果:
                              <span style="color: #63a103;" :class="e.processResult == 1 ? '' : 'spnacitive'">【{{
                                e.processResult == 1 ? "通过" : "不通过" }}】</span>
                            </div>
                            <div v-for="(x, c) in e.commentList">
                              <div v-if="x.comment">审核意见： {{ x.comment }}</div>
                              <img style="width: 50px;height: 50px;" v-for="(d, f) in x.commentImgList"
                                :src="d.commentImgUrl" v-if="d.commentImgUrl" alt="">
                            </div>
                          </div>
                        </div>

                      </template>

                    </el-step>

                  </el-steps>
                </div>

              </el-card>
            </div>

          </div>
          <div class="drawer-bottom-right">
            <!-- <div class="team-user">
              <div class="team-title">团队成员</div>
              <div class="team-bottom">
                <span class="team-label">客户合伙人</span>
                <span class="team-value">张三</span>
              </div>
            </div>
            <div class="logList">
              <div class="log-title">
                <span>跟进记录</span>
                <el-select @change="changeAdvanced" v-model="followRecordForm.followType" style="width:120px">
                  <el-option label="待提交审核" value="待提交审核"></el-option>
                  <el-option label="待资料审核" value="待资料审核"></el-option>
                  <el-option label="待发货申请" value="待发货申请"></el-option>
                  <el-option label="待发货调度" value="待发货调度"></el-option>
                  <el-option label="待安装完工" value="待安装完工"></el-option>
                  <el-option label="待验收派单" value="待验收派单"></el-option>
                  <el-option label="待现场验收" value="待现场验收"></el-option>
                  <el-option label="待消缺整改" value="待消缺整改"></el-option>
                  <el-option label="已完工验收" value="已完工验收"></el-option> </el-select>
              </div>
              <el-input v-model="followRecordForm.followRemark" style="margin-top: 10px" type="textarea"
                placeholder="请添加跟进记录" maxlength="300" show-word-limit>
              </el-input>
              <div style="margin-top: 10px;">
                <el-badge :hidden="followRecordForm.followAttachmentList.length == 0"
                  :value="followRecordForm.followAttachmentList.length" class="item">
                  <el-button @click="uploadAttachmentList" style="
                  color: #fff;
                  background: #2e64ee;
                ">点击上传附件</el-button>
                </el-badge>
              </div>


            </div>
            <div class="two-btns">
              <el-button @click="drawer = false" style="margin-top: 10px; font-size: 12px">取消</el-button>
              <el-button @click="createFollowRecord" style="
                  margin-top: 10px;
                  color: #fff;
                  background: #2e64ee;
                  font-size: 12px;
                ">保存</el-button>
            </div> -->
            <div class="draw-record">
              <div class="history-record">历史记录</div>
              <div class="step-record">
                <el-steps direction="vertical" align-center :active="logSteplist.length">
                  <el-step v-for="item, index in logSteplist" :key="index">
                    <template slot="title">
                      <div class="step-time" style="margin-top: 0px;" icon="el-icon-edit">{{ item.operateType +
                        (item.operateStatus == 1 ? "成功" : "失败") }}
                      </div>
                    </template>
                  </el-step>
                  <!-- <el-step v-for="item, index in logSteplist" :key="index">
                    <template slot="title">
                      <div class="step-time">{{ item.createDate }}
                        <div class="delBtn" v-if="index == 0">
                          <i @click="delBtn(item.id)" class="el-icon-circle-close"></i>
                        </div>
                      </div>
                    </template>
                    <template slot="description">
                      <div class="step-text" style="padding-bottom:6px">
                        <div class="step-text-title">
                          <span v-show="item.followType">【</span>{{ item.followType }}<span
                            v-show="item.followType">】</span>{{ item.customerName }}
                        </div>
                        <div class="step-text-desc">{{ item.followRemark }}</div>
                        <div @click="showAttach(item.followAttachmentList)"
                          v-if="item.followAttachmentList && item.followAttachmentList.length > 0"
                          style="color:#f59a23;cursor:pointer;width:fit-content;display:flex;align-items:center">
                          <i class="el-icon-paperclip"></i><span style="padding-left:2px">附件</span>
                        </div>
                      </div>
                    </template>
                  </el-step> -->
                </el-steps>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-dialog class="padding10" append-to-body title="附件" v-dialogDrag :visible.sync="dialogVisibleAttachView">
        <el-form label-width="90px">
          <el-form-item label="附件内容：">
            <el-image style="height:100px;width:100px;margin:0 4px" :preview-src-list="currentFile" :src="item"
              v-for="item, index in currentFile" :key="index"></el-image>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleAttachView = false">关 闭</el-button>
        </span>
      </el-dialog>
      <el-dialog class="padding10" append-to-body title="附件" v-dialogDrag :visible.sync="dialogVisibleAttach">
        <el-form label-width="90px">
          <el-form-item label="附件：">
            <el-upload :action="$uploadURL" class="attach" :on-success="handleAttachSuccess" list-type="picture-card"
              :file-list="followRecordForm.followAttachmentList" :on-preview="handlePictureCardPreview"
              :on-remove="handleRemoveAttach">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleAttach = false">关 闭</el-button>
          <el-button type="primary" @click="dialogVisibleAttach = false">保 存</el-button>
        </span>
      </el-dialog>
    </el-drawer>
    <el-dialog v-dialogDrag custom-class="addUserDialog" :visible.sync="dialogVisible" width="1000px">
      <template slot="title">
        <span style="font-size: 15px; font-weight: 600; color: #333">新增客户</span></template>
      <div class="dialog-main">
        <div class="block-title">客户类型</div>
        <div class="dialog-threeBtn">
          <el-button @click="customertype = 1" :class="{ 'active-btn': customertype == 1 }">自然人</el-button>
          <el-button @click="customertype = 2" :class="{ 'active-btn': customertype == 2 }">公 司</el-button>
          <el-button @click="customertype = 3" :class="{ 'active-btn': customertype == 3 }">公共屋顶</el-button>
        </div>
        <!-- 自然人 -->
        <div class="buttonIndex1" v-show="customertype == 1">
          <div style="margin-top: 16px" class="block-title">基本信息</div>
          <el-form label-width="90px">
            <el-row class="flexrow">
              <el-col :span="8">
                <el-form-item label="姓名"><el-input v-model="form1.customerName" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="手机号"><el-input v-model="form1.mobile" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="归属公司" required>
                  <el-select style="width: 100%" value-key="organCode" v-model="isFirstObj">
                    <el-option :label="item.organName" :value="item" v-for="(item, index) in companyOptions"
                      :key="index"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="身份证号">
                  <el-input @change="setForm1Sex" v-model="form1.certificateNo" class="small-height-input"
                    size="mini"></el-input>
                </el-form-item>
              </el-col>
              <!-- line2 -->
              <el-col :span="8">
                <el-form-item label="订单进度">
                  <el-select class="small-height-input" size="mini" v-model="form1.orderSchedule">
                    <el-option label="待提交审核" value="待提交审核"></el-option>
                    <el-option label="待资料审核" value="待资料审核"></el-option>
                    <el-option label="待发货申请" value="待发货申请"></el-option>
                    <el-option label="待发货调度" value="待发货调度"></el-option>
                    <el-option label="待安装完工" value="待安装完工"></el-option>
                    <el-option label="待验收派单" value="待验收派单"></el-option>
                    <el-option label="待现场验收" value="待现场验收"></el-option>
                    <el-option label="待消缺整改" value="待消缺整改"></el-option>
                    <el-option label="已完工验收" value="已完工验收"></el-option> </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="订单状态">
                  <el-select v-model="form1.orderStatus">
                    <el-option label="正常" value="正常"></el-option>
                    <el-option label="关闭" value="关闭"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="项目公司">
                  <el-input v-model="form1.projectCompanyName" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="地址">
                  <el-input v-model="form1.address" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="销售姓名"><el-input v-model="form1.sellName" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注">
                  <el-input v-model="form1.remark" type="textarea" placeholder="请输入内容">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- 施工班组 -->
          <div style="margin-top: 16px" class="block-title">施工班组</div>
          <el-form label-width="90px">
            <el-row class="flexrow">
              <el-col :span="8">
                <el-form-item label="班组名称"><el-input v-model="form1.constructionName" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系人"><el-input v-model="form1.constructionLinkman" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="手机号" required>
                  <el-input v-model="form1.constructionPhone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注">
                  <el-input v-model="form1.constructionRemark" type="textarea" placeholder="请输入内容">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!--公司  -->
        <div class="buttonIndex1" v-show="customertype == 2">
          <div style="margin-top: 16px" class="block-title">公司信息</div>
          <el-form label-width="90px">
            <el-row>
              <el-col :span="8">
                <el-form-item label="公司名称">
                  <el-input v-model="form2.companyName" placeholder="请输入公司名称" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="法人">
                  <el-input v-model="form2.legalName" placeholder="请输入法人姓名" class="small-height-input"
                    size="mini"></el-input>
                </el-form-item>
              </el-col>
              <!-- line2 -->
              <el-col :span="8">
                <el-form-item label="法人手机">
                  <el-input v-model="form2.legalMobile" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="客户类型">
                  <el-select v-model="form2.life" class="small-height-input" size="mini">
                    <el-option label="新客户" :value="1"></el-option>
                    <el-option label="潜在客户" :value="2"></el-option>
                    <el-option label="意向客户" :value="3"></el-option>
                    <el-option label="商机客户" :value="4"></el-option>
                    <el-option label="流失客户" :value="5"></el-option>
                    <el-option label="成交客户" :value="6"></el-option> </el-select></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="行业类型">
                  <el-select v-model="form2.industryType" class="small-height-input" size="mini">
                    <el-option label="行业一" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="证件类型">
                  <el-select v-model="form2.legalCertificateType" class="small-height-input" size="mini">
                    <el-option label="证件类型一" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="证件号">
                  <el-input v-model="form2.certificateNo" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="所在城市">
                  <el-input v-model="form2.legalCityName" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="详细地址">
                  <el-input v-model="form2.address" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="人员规模">
                  <el-input v-model="form2.companyScale" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="公司网址">
                  <el-input v-model="form2.companyGw" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="创建人">
                  <el-input v-model="form2.createName" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="创建时间">
                  <el-date-picker style="width: 194px" v-model="form2.createDate" value-format="yyyy-MM-dd"
                    class="small-height-input" type="date" placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注">
                  <el-input type="textarea" placeholder="请输入内容" v-model="form2.companyRemark">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- 施工班组 -->
          <div style="margin-top: 16px" class="block-title">施工班组</div>
          <el-form label-width="90px">
            <el-row class="flexrow">
              <el-col :span="8">
                <el-form-item label="班组名称"><el-input v-model="form2.constructionName" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系人"><el-input v-model="form2.constructionLinkman" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="手机号" required>
                  <el-input v-model="form2.constructionPhone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注">
                  <el-input v-model="form2.constructionRemark" type="textarea" placeholder="请输入内容">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!-- 公共屋顶 -->
        <div class="buttonIndex1" v-show="customertype == 3">
          <div style="margin-top: 16px" class="block-title">公司信息</div>
          <el-form label-width="90px">
            <el-row>
              <el-col :span="8">
                <el-form-item label="公司名称">
                  <el-input v-model="form3.companyName" placeholder="请输入公司名称" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <!-- <el-col :span="8">
                <el-form-item label="公司编号"
                  ><el-input
                    v-model="form3.companyCode"
                    class="small-height-input"
                    size="mini"
                  ></el-input
                ></el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="法人">
                  <el-input v-model="form3.legalName" placeholder="请输入法人姓名" class="small-height-input"
                    size="mini"></el-input>
                </el-form-item>
              </el-col>
              <!-- line2 -->
              <el-col :span="8">
                <el-form-item label="法人手机">
                  <el-input v-model="form3.legalMobile" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="客户类型">
                  <el-select v-model="form3.life" class="small-height-input" size="mini">
                    <el-option label="新客户" :value="1"></el-option>
                    <el-option label="潜在客户" :value="2"></el-option>
                    <el-option label="意向客户" :value="3"></el-option>
                    <el-option label="商机客户" :value="4"></el-option>
                    <el-option label="流失客户" :value="5"></el-option>
                    <el-option label="成交客户" :value="6"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="行业类型">
                  <el-input v-model="form3.industryType" class="small-height-input" size="mini">
                    <!-- <el-option label="行业一" :value="1"></el-option> -->
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="证件类型">
                  <el-input v-model="form3.legalCertificateType" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="证件号">
                  <el-input v-model="form2.certificateNo" class="small-height-input" size="mini">

                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="所在城市">
                  <el-input v-model="form3.legalCityName" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="详细地址">
                  <el-input v-model="form3.address" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="8">
            <el-form-item label="客户来源">
                <el-input v-model="form3.source" readonly class="small-height-input" size="mini">

                </el-input>
            </el-form-item>
        </el-col> -->
              <el-col :span="8">
                <el-form-item label="人员规模">
                  <el-input v-model="form3.companyScale" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="公司网址">
                  <el-input v-model="form3.companyGw" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="创建人">
                  <el-input v-model="form3.createName" class="small-height-input" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="创建时间">
                  <el-date-picker style="width: 194px" v-model="form3.createDate" value-format="yyyy-MM-dd"
                    class="small-height-input" type="date" placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注">
                  <el-input type="textarea" placeholder="请输入内容" v-model="form3.companyRemark">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- 施工班组 -->
          <div style="margin-top: 16px" class="block-title">施工班组</div>
          <el-form label-width="90px">
            <el-row class="flexrow">
              <el-col :span="8">
                <el-form-item label="班组名称"><el-input v-model="form3.constructionName" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系人"><el-input v-model="form3.constructionLinkman" class="small-height-input"
                    size="mini"></el-input></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="手机号" required>
                  <el-input v-model="form3.constructionPhone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注">
                  <el-input v-model="form3.constructionRemark" type="textarea" placeholder="请输入内容">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="dialog-foot-btn">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="formLoading" @click="saveForm">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 并网 -->
    <el-dialog v-dialogDrag custom-class="dialogBingWang uploadDialog body70 head0"
      :visible.sync="dialogVisibleBingWang" width="1100px">
      <template slot="title">
        <span style="font-size: 15px; font-weight: 600; color: #333">并网资料___{{ currRowObj.customerName }}({{
          currRowObj.mobile
        }})</span></template>
      <el-form style="height: 65vh; overflow: auto">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="发用电合同">
              <el-upload drag multiple :file-list="bingWangForm.fydht" :class="{
                diyUpload: bingWangForm.fydht.length == 0,
                diyHeight: bingWangForm.fydht.length != 0,
              }" :on-success="(res, file) => {
                handleSuccess(res, file, 'fydht');
              }
                " :action="$uploadURL" list-type="picture-card" :on-preview="(res, file) => {
                  handlePictureCardPreview(res, file);
                }
                  " :on-remove="(res, file) => {
                    handleRemove(res, file, 'fydht');
                  }
                    ">
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">请上传发用电合同</div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="逆变器正常运行">
              <el-upload drag multiple :file-list="bingWangForm.nbqzcyx" :class="{
                diyUpload: bingWangForm.nbqzcyx.length == 0,
                diyHeight: bingWangForm.nbqzcyx.length != 0,
              }" :on-success="(res, file) => {
                handleSuccess(res, file, 'nbqzcyx');
              }
                " :action="$uploadURL" list-type="picture-card" :on-preview="(res, file) => {
                  handlePictureCardPreview(res, file);
                }
                  " :on-remove="(res, file) => {
                    handleRemove(res, file, 'nbqzcyx');
                  }
                    ">
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">
                  请上传逆变器正常运行
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发电电表照片">
              <el-upload drag multiple :file-list="bingWangForm.fddbzp" :class="{
                diyUpload: bingWangForm.fddbzp.length == 0,
                diyHeight: bingWangForm.fddbzp.length != 0,
              }" :on-success="(res, file) => {
                handleSuccess(res, file, 'fddbzp');
              }
                " :action="$uploadURL" list-type="picture-card" :on-preview="(res, file) => {
                  handlePictureCardPreview(res, file);
                }
                  " :on-remove="(res, file) => {
                    handleRemove(res, file, 'fddbzp');
                  }
                    ">
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">请上传发电电表照片</div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发电户号凭证">
              <el-upload drag multiple :file-list="bingWangForm.fdhhpz" :class="{
                diyUpload: bingWangForm.fdhhpz.length == 0,
                diyHeight: bingWangForm.fdhhpz.length != 0,
              }" :on-success="(res, file) => {
                handleSuccess(res, file, 'fdhhpz');
              }
                " :action="$uploadURL" list-type="picture-card" :on-preview="(res, file) => {
                  handlePictureCardPreview(res, file);
                }
                  " :on-remove="(res, file) => {
                    handleRemove(res, file, 'fdhhpz');
                  }
                    ">
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">请上传发电户号凭证</div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备移交确认单">
              <el-upload drag multiple :file-list="bingWangForm.sbyjqrd" :class="{
                diyUpload: bingWangForm.sbyjqrd.length == 0,
                diyHeight: bingWangForm.sbyjqrd.length != 0,
              }" :on-success="(res, file) => {
                handleSuccess(res, file, 'sbyjqrd');
              }
                " :action="$uploadURL" list-type="picture-card" :on-preview="(res, file) => {
                  handlePictureCardPreview(res, file);
                }
                  " :on-remove="(res, file) => {
                    handleRemove(res, file, 'sbyjqrd');
                  }
                    ">
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">
                  请上传设备移交确认单
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发用电合同日期">
              <el-date-picker v-model="bingWangForm.contractDate" type="date" value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-dialog append-to-body :visible.sync="dialogVisibleImg">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <div class="dialog-foot-btn" style="position: relative; bottom: -11px">
        <el-button @click="dialogVisibleBingWang = false">取 消</el-button>
        <el-button type="primary" :loading="bingWangLoading" @click="bingWangBtn">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 报装 -->
    <el-dialog v-dialogDrag custom-class="dialogBingWang uploadDialog wlStyle" :visible.sync="dialogVisibleBaoZhuang"
      width="1100px">
      <template slot="title">
        <span style="font-size: 15px; font-weight: 600; color: #333">报装资料___{{ currRowObj.customerName }}({{
          currRowObj.mobile
        }})</span></template>

      <el-form class="noscollBar" style="height: 65vh; overflow: auto">
        <el-row :gutter="20">
          <el-col :span="12" v-for="(item, index) in baoZhuangList" :key="index">
            <el-form-item :label="index + 1 + '、' + item.label">
              <el-upload drag multiple :file-list="baoZhuangForm[item.type]" :class="{
                diyUpload: baoZhuangForm[item.type].length == 0,
                diyHeight: baoZhuangForm[item.type].length != 0,
              }" :on-success="(res, file) => {
                handleSuccessBZ(res, file, item.type);
              }
                " :action="$uploadURL" list-type="picture-card" :on-preview="(res, file) => {
                  handlePictureCardPreview(res, file);
                }
                  " :on-remove="(res, file) => {
                    handleRemoveBZ(res, file, item.type);
                  }
                    ">
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">
                  请上传{{ item.label }}
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-dialog append-to-body :visible.sync="dialogVisibleImg">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>

      <div style="height: 36px"></div>
      <div class="dialog-foot-btn">
        <el-button @click="dialogVisibleBaoZhuang = false">取 消</el-button>

        <el-button type="primary" :loading="baoZhuangLoading" @click="saveStep1">确认保存</el-button>
      </div>
    </el-dialog>
    <!--物料  -->
    <el-dialog v-dialogDrag custom-class="dialogBingWang uploadDialog wlStyle"
      :visible.sync="dialogVisibleBaoZhuangStep2" width="1100px">
      <template slot="title">
        <span style="font-size: 15px; font-weight: 600; color: #333">物料</span></template>
      <el-dialog v-dialogDrag custom-class="wlStyle headpadding" append-to-body :visible.sync="dialogVisibleWL"
        width="60%">
        <template slot="title">
          <span style="font-size: 15px; font-weight: 600; color: #333">请选择物料</span></template>
        <!-- <el-form>
    <el-form-item></el-form-item>
    </el-form>  -->
        <el-table :data="tableWl" ref="tableWl" border height="70vh" @selection-change="handleSelectionChangeWl"
          @row-click="handleRowClickWl" v-loading="dialogInnerWlLoading" style="width: 100%">
          <el-table-column type="selection" align="center"> </el-table-column>
          <!-- <el-table-column prop="comboCode" align="center" label="物料编号">
          </el-table-column> -->
          <el-table-column prop="productName" label="物料名称" align="center">
          </el-table-column>
          <el-table-column prop="productTypeName" label="物料类型名称" align="center">
          </el-table-column>
          <el-table-column prop="price" label="价格/元" align="center">
          </el-table-column>
          <el-table-column prop="power" label="功率" align="center">
          </el-table-column>
          <el-table-column prop="unit" label="单位" align="center">
          </el-table-column>
          <el-table-column prop="specs" label="规格/型号" align="center">
          </el-table-column>
          <el-table-column prop="brand" label="品牌" align="center">
          </el-table-column>
          <el-table-column prop="agentName" label="代理商名称" align="center">
          </el-table-column>
          <!-- <el-table-column
      prop="unit"
      label="单位">
    </el-table-column> -->
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleWL = false">取 消</el-button>
          <el-button type="primary" @click="wlSelectedBtn">确 定</el-button>
        </span>
      </el-dialog>
      <div style="height: 65vh; overflow: auto" class="noscollBar step2">
        <div style="margin-left: 12px" class="block-title">物料信息</div>
        <div style="margin-top: 8px">
          <el-input style="width: 180px" v-model="select" @focus="showWl" size="mini">
          </el-input>
          <el-button @click="showWl" style="margin-left: 10px" type="primary">选择物料</el-button>

          <el-table :data="tableWlInfoData" border height="50vh" style="width: 100%">
            <el-table-column type="index" label="序号" align="center">
            </el-table-column>
            <el-table-column align="center" prop="productName" label="物料名称">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="specs" align="center" label="规格型号">
              <!-- <template slot-scope="{ row }">
                <el-input v-model="row.specs"></el-input>
              </template> -->
            </el-table-column>
            <el-table-column prop="brand" align="center" label="品牌">
              <!-- <template slot-scope="{ row }">
                <el-input v-model="row.brand"></el-input>
              </template> -->
            </el-table-column>
            <el-table-column prop="productTypeName" align="center" label="物料类型">
              <template slot-scope="{ row }">
                <span v-if="row.productTypeName == '组件'" style="color: red">{{
                  row.productTypeName
                }}</span>
                <span v-else>{{ row.productTypeName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="unit" align="center" label="单位">
              <!-- <template slot-scope="{ row }">
                <el-input v-model="row.unit"></el-input>
              </template> -->
            </el-table-column>
            <el-table-column prop="unitPrice" align="center" label="物料单价/元">
              <!-- <template slot-scope="{ row }">
                <el-input v-model="row.unitPrice"></el-input>
              </template> -->
            </el-table-column>
            <el-table-column prop="power" align="center" label="功率">
            </el-table-column>
            <el-table-column prop="num" align="center" label="数量/台数">
              <template slot-scope="{ row }">
                <el-input-number style="width: 90%" controls-position="right" v-model="row.num"></el-input-number>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="planReachDate"
              align="center"
              min-width="136px"
              label="计划到货时间"
            >
              <template slot-scope="{ row }">
                <el-date-picker
                  style="width: 130px"
                  v-model="row.planReachDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </template>
            </el-table-column> -->
            <el-table-column prop="remark" align="center" label="备注">
              <template slot-scope="{ row }">
                <el-input v-model="row.remark"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="height: 36px"></div>
      <div class="dialog-foot-btn">
        <el-button @click="dialogVisibleBaoZhuangStep2 = false">取 消</el-button>
        <el-button type="primary" @click="saveWl">保 存</el-button>
      </div>
    </el-dialog>
    <distribution ref="distributionRef"></distribution>
    <el-dialog title="图片" :visible.sync="dialogVisibleImg" width="35%">
      <img width="100%" :src="dialogImageUrl" alt="" class="bigImg" />
    </el-dialog>
    <!-- 日志 -->
    <logs ref="logsRef"></logs>
    <!--  -->
    <construction ref="constructionRef"></construction>
  </div>
</template>

<script>
import drag from "../../components/drag";
import logs from "./diglog/customLogs";
import construction from './diglog/constructionOrder'
import distribution from "./digLogCompletion/distributionDig";
import { add, remove } from "./common";
export default {
  components: { distribution, logs, construction },
  data() {
    return {
      AccessInfo: {},
      setColorByStatus: [
        { status: "待审核提交", className: "set-1" },
        { status: "待资料审核", className: "set-2" },
        { status: "待发货申请", className: "set-3" },
        { status: "待发货调度", className: "set-4" },
        { status: "待安装完工", className: "set-5" },
        { status: "待验收派单", className: "set-6" },
        { status: "待现场验收", className: "set-7" },
        { status: "待消缺整改", className: "set-8" },
        { status: "已完工验收", className: "set-9" },
      ],
      currentFile: [],
      dialogVisibleAttachView: false,
      attachCode: "",
      attachLogList: [],
      dialogVisibleAttach: false,
      customerInfoForm: {
        baseInfo: {
          //基本信息
          certificateNo: '',
          constructionName: "",//施工班组
          constructionLinkman: "",
          constructionPhone: "",
          constructionRemark: "",
          customerType: "",
          id: "",
          life: "",
          customerName: "", //客户名称
          mobile: "", //手机号
          // customerCode:"",//客户编号
          sex: "", //性别 1男2女 3未知
          birthday: "", //生日
          age: "", //年龄
          wxNo: "", //微信,
          remark: "",
          nickname: "", //客户昵称
          cityName: "",
          eMail: "",
          certificateType: "", //证件类型
          certificateCode: "", //证件
          dyNickname: "",
          ttNickname: "",
          partner: "",
          agentCode: "",
          companyManager: "",
          headManager: "",
          createName: "",
          createDate: "",
          address: "",
          orderSchedule: "",
          orderStatus: "",
          sellName: "",
          projectCompanyName: ""
        },
        companyInfo: { //公司信息

        }
      },
      isFirstObj: "",
      companyOptions: [],
      dialogVisibleBaoZhuangStep2: false,
      customerCode: "",
      customBasicInfo: {}, //客户基本信息
      onGridInfo: {}, //并网信息
      dialogVisibleBaoZhuang: false,
      imgVisible: false, //图片预览弹窗
      dialogImageUrl: "", //预览图片地址
      bzActiveName: "first",
      queryOneId: null,
      tableWlInfoData: [],
      selectWl: [],
      dialogInnerWlLoading: false,
      select: "",
      tableWl: [],
      dialogVisibleWL: false,
      baoZhuangList: [
        {
          label: "安装区域细节照片",
          type: "azqyxj",
        },
        {
          label: "施工安全技术交底作业单",
          type: "sgaqjsjdzyd",
        },
        {
          label: "施工组长出工照",
          type: "sgzzcg",
        },
        {
          label: "施工组员出工照",
          type: "sgzycg",
        },
        {
          label: "支撑点距离南侧内檐照片",
          type: "zcdjlncny",
        },
        {
          label: "支撑点距离东 (西) 侧内照片",
          type: "zcdjldxcnyzp",
        },
        {
          label: "支架南面东向西照片",
          type: "zjnmdxx",
        },
        {
          label: "支架南面西向东照片",
          type: "zjnmxxd",
        },
        {
          label: "支架北侧东向西照片",
          type: "zjbcdxx",
        },
        {
          label: "支架北侧西向东照片",
          type: "zjbcxxd",
        },
        {
          label: "支架倾角照片",
          type: "zjqj",
        },
        {
          label: "三角支撑照片",
          type: "sjzc",
        },
        {
          label: "直流电压测试照片(按回路数量拍摄，每个回路最少一张)",
          type: "zldycs",
        },
        {
          label: "配电箱内部照片",
          type: "pdxnb",
        },
        {
          label: "配电箱外部照片(含电站门牌)",
          type: "pdxwb",
        },
        {
          label: "逆变器位置",
          type: "nbqwz",
        },
        {
          label: "逆变器条形码(含模块条形码)",
          type: "nbqtxm",
        },
        {
          label: "组件间接地、组件与支架间接地照片",
          type: "zjjjdzjyzjjjd",
        },
        {
          label: "组件及配电箱接地电阻测试照片",
          type: "zjjpdxjddzcs",
        },
        {
          label: "竣工电站北向南照片",
          type: "jgdzbxn",
        },
        {
          label: "组件铭牌照片",
          type: "zjmp",
        },
        {
          label: "电站全景照片",
          type: "dzqj",
        },
        {
          label: "安装完工自检表",
          type: "azwgzjb",
        },
      ],
      baoZhuangForm: {
        azqyxj: [],
        sgaqjsjdzyd: [],
        sgzzcg: [],
        sgzycg: [],
        zcdjlncny: [],
        zcdjldxcnyzp: [],
        zjnmdxx: [],
        zjnmxxd: [],
        zjbcdxx: [],
        zjbcxxd: [],
        zjqj: [],
        sjzc: [],
        zldycs: [],
        pdxnb: [],
        pdxwb: [],
        nbqwz: [],
        nbqtxm: [],
        zjjjdzjyzjjjd: [],
        zjjpdxjddzcs: [],
        jgdzbxn: [],
        zjmp: [],
        dzqj: [],
        azwgzjb: [],
      },

      baoZhuangLoading: false,
      tableLoading: false,
      currRowObj: {
        id: "",
        customerCode: "",
        customerName: "",
        mobile: "",
      },
      bingWangLoading: false,
      bingWangForm: {
        id: "",
        fydht: [],
        nbqzcyx: [],
        fddbzp: [],
        fdhhpz: [],
        sbyjqrd: [],
        contractDate: "",
      },
      dialogImageUrl: "",
      dialogVisibleImg: false,
      dialogVisibleBingWang: false,
      total: 0,
      queryInfo: {
        pageSize: 100,
        currPage: 1,
        condition: {
          agentCode: "",
          customerName: "",
          orderSchedule: "",
          customerNumber: ""
        },
      },
      select: "",
      textarea: "",
      formLoading: false,
      customertype: 1,
      labelType: "add",
      form1: {
        //个人
        customerName: "", //姓名
        mobile: "",
        certificateNo: "",
        orderSchedule: "",
        orderStatus: "",
        projectCompanyName: "",
        address: "",
        sellName: "",
        remark: "",
        constructionName: "",
        constructionLinkman: "",
        constructionPhone: "",
        constructionRemark: ""
      },
      form2: {
        constructionName: "",
        constructionLinkman: "",
        constructionPhone: "",
        constructionRemark: "",
        //公司信息
        companyName: "",
        companyCode: "",
        legalName: "",
        life: "",
        industryType: "",
        legalCertificateType: "",
        legalCityName: "",
        certificateNo: "",
        legalCityName: "",
        address: "",
        source: 1,
        companyScale: "",
        companyGw: "",
        createName: "",
        createDate: "",
        companyRemark: "",
      },
      form3: {
        constructionName: "",
        constructionLinkman: "",
        constructionPhone: "",
        constructionRemark: "",
        //公司信息
        companyName: "",
        companyCode: "",
        legalName: "",
        life: "",
        industryType: "",
        legalCertificateType: "",
        legalCityName: "",
        certificateNo: "",
        legalCityName: "",
        address: "",
        source: 1,
        companyScale: "",
        companyGw: "",
        createName: "",
        createDate: "",
        companyRemark: "",
      },
      dialogVisible: false,
      tableData: [],
      drawer: false,
      activeName: "second",
      dialogInnerWlLoading: false,
      followRecordForm: {
        customerCode: "",
        customerNumber: "",
        customerName: "",
        followUserCode: JSON.parse(localStorage.getItem('userInfo')).userInfo.userCode,//（登录用户）跟进人编号
        followUserName: JSON.parse(localStorage.getItem('userInfo')).userInfo.name,//（登录用户）跟进人名称
        followType: "",// 跟进类型
        followRemark: "",// 跟进备注
        followAttachmentList: []//(fileName,fileUrl)跟进附件
      },
      multipleSelection: [],
      sceneSouthfacade: [],
      action: this.$uploadURL,
      certificateImg1List: [],// 证照信息人面照
      certificateImg2List: [],// 证件信息国徽照
      bankcardImg1List: [], // 银行卡正面
      corporateAccountList: [], // 对公账户
      businessLicenseList: [], // 营业执照照片
      mainGateImgList: [],
      fullViewImgList: [],
      mainRoofImgList: [],
      sceneEastImgList: [],
      sceneEastImgList: [],
      sceneSoutheastImgList: [],
      sceneSouthImgList: [],
      sceneSouthwestImgList: [],
      sceneWestImgList: [],
      sceneSouthfacadeImgList: [],
      sceneNorthfacadeImgList: [],
      sceneVideoList: [],
      azimuthImgList: [],
      installImgList: [],
      explorationObj: {},

      businessLicenseList2: [], // 出租方营业执照
      corporateAccountList2: [], // 对公账号信息
      organLicenseList: [], // 附属机构（营业执照）
      bwApplyList: [], // 并网申请表
      propertyCertificateList: [], // 房屋产权证明
      installationStatementList: [], // 晴天阁特殊安装声明
      titleCommitmentList: [], // 产权承诺书
      signdetail: {},
      BusinessInfoDate: [],
      logSteplist: [],
      rightdata: [],
    };
  },
  created() {
    // if (localStorage.getItem("customerListQueryKey")) {
    //   this.queryInfo.condition.agentCode = localStorage.getItem(
    //     "customerListQueryKey"
    //   );
    // }
    this.getTable();
    this.getCompanyList();
    // this.getAccessInfo()
  },
  methods: {
    back() {
      this.$router.push({ path: '/Welcome' });
    },
    // 获取头部数据
    async getAccessInfo() {
      let res = await this.$http.post('houseCustomer/queryAccessInfo', {
        agentCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .agentCode,
      });
      if (res.data.code == 200) {
        this.AccessInfo = res.data.data
      }
    },
    toConstruction() {
      this.$refs.constructionRef.init()
    },
    // 分配
    async distribution() {
      this.$refs.distributionRef.init(this.multipleSelection)
      // let res = await this.$http.post('houseCustomer/allocation', this.multipleSelection )
      // this.$message({
      //   message: '分配成功',
      //   type: 'success'
      // });
      // this.$router.push({path: '/expoleManage'})
    },
    // 获取日志数据
    async getLogList(code) {
      let res = await this.$http.post('houseOperateLog/queryLogList', {
        customerCode: code
      });
      if (res.data.code == 200) {
        this.logSteplist = res.data.data.reverse()
      }
    },
    // 日志
    showLogs(row) {
      this.$refs.logsRef.init(row)
    },
    // 证件人面像
    handelCertificateImg1(response, file, fileList) {
      this.certificateImg1List = add(
        "certificateImg1",
        response.data.url,
        this.certificateImg1List
      );
    },
    // 证件国徽面
    handelCertificateImg2(response, file, fileList) {
      this.certificateImg2List = add(
        "certificateImg2",
        response.data.url,
        this.certificateImg2List
      );
    },
    // 对公账户
    handelCorporateAccount(response, file, fileList) {
      this.corporateAccountList = add(
        "corporateAccount",
        response.data.url,
        this.corporateAccountList
      );
    },
    // 营业执照照片
    handelBusinessLicense(response, file, fileList) {
      this.businessLicenseList = add(
        "businessLicense",
        response.data.url,
        this.businessLicenseList
      );
    },
    // 银行卡正面
    handelBankcardImg1(response, file, fileList) {
      this.bankcardImg1List = add(
        "bankcardImg1",
        response.data.url,
        this.bankcardImg1List
      );
    },

    // 出租方营业执照
    handelBusinessLicense2(response, file, fileList) {
      this.businessLicenseList2 = add(
        "businessLicense",
        response.data.url,
        this.businessLicenseList2
      );
    },
    // 对公账号信息
    handelCorporateAccount2(response, file, fileList) {
      this.corporateAccountList2 = add(
        "corporateAccount",
        response.data.url,
        this.corporateAccountList2
      );
    },

    // 附属机构（营业执照）
    handelOrganLicense(response, file, fileList) {
      this.organLicenseList = add(
        "organLicense",
        response.data.url,
        this.organLicenseList
      );
    },
    // 并网申请表
    handelBwApply(response, file, fileList) {
      this.bwApplyList = add(
        "bwApply",
        response.data.url,
        this.bwApplyList
      );
    },
    // 房屋产权证明
    handelPropertyCertificate(response, file, fileList) {
      this.propertyCertificateList = add(
        "propertyCertificate",
        response.data.url,
        this.propertyCertificateList
      );
    },
    // 晴天阁特殊安装声明
    handelInstallationStatement(response, file, fileList) {
      this.installationStatementList = add(
        "installationStatement",
        response.data.url,
        this.installationStatementList
      );
    },
    // 产权承诺书
    handelTitleCommitment(response, file, fileList) {
      this.titleCommitmentList = add(
        "titleCommitment",
        response.data.url,
        this.titleCommitmentList
      );
    },

    // 删除 图片
    handelRemoveSceneSouthfacade(item, list) {
      remove(item.explorateImgUrl, this[list]);
    },
    // 查看大图
    handelImage(item) {
      this.dialogVisibleImg = true;
      this.dialogImageUrl = item.explorateImgUrl;
    },
    //获取客户基本信息
    async getCustomBasicInfo() {
      let res = await this.$http.post("/houseCustomer/queryOne", {
        customerCode: this.customerCode,
      });
      if (res.data.code == 200) {
        for (let k in this.customerInfoForm.baseInfo) {
          if (!res.data.data[k] == undefined) {
          }
          this.customerInfoForm.baseInfo[k] = res.data.data[k];
        }
        this.certificateImg1List = []
        this.certificateImg2List = []
        this.bankcardImg1List = []
        this.corporateAccountList = []
        this.businessLicenseList = []
        res.data.data.certificateImg1 ? this.certificateImg1List.push({ explorateImgUrl: res.data.data.certificateImg1 }) : ''
        res.data.data.certificateImg2 ? this.certificateImg2List.push({ explorateImgUrl: res.data.data.certificateImg2 }) : ''
        res.data.data.bankcardImg1 ? this.bankcardImg1List.push({ explorateImgUrl: res.data.data.bankcardImg1 }) : ''
        res.data.data.corporateAccount ? this.corporateAccountList.push({ explorateImgUrl: res.data.data.corporateAccount }) : ''
        res.data.data.businessLicense ? this.businessLicenseList.push({ explorateImgUrl: res.data.data.businessLicense }) : ''
      } else {
        this.$message.error(res.data.message);
      }
    },

    // 基本信息保存
    async saveBaseInfo(type) {
      let data = JSON.parse(JSON.stringify(this.customerInfoForm[type]));
      data.certificateImg1 = this.certificateImg1List[0] ? this.certificateImg1List[0].explorateImgUrl : ''
      data.certificateImg2 = this.certificateImg2List[0] ? this.certificateImg2List[0].explorateImgUrl : ''
      data.bankcardImg1 = this.bankcardImg1List[0] ? this.bankcardImg1List[0].explorateImgUrl : ''
      data.corporateAccount = this.corporateAccountList[0] ? this.corporateAccountList[0].explorateImgUrl : ''
      data.businessLicense = this.businessLicenseList[0] ? this.businessLicenseList[0].explorateImgUrl : ''
      let index = this.companyOptions.findIndex(
        (item) => item.agentCode == data.agentCode
      );
      if (index != -1) {
        data.agentName = this.companyOptions[index].organName
      }
      let res = await this.$http.post("houseCustomer/update", data);
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        this.drawer = false;
        this.getTable();
        this.getCustomBasicInfo();
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getExploration(customerCode) {
      let res = await this.$http.post('/houseExplorateHouse/queryBussineExplorate', { customerCode })
      if (res.data.code == 200) {
        let data = res.data.data
        this.mainGateImgList = data.mainGateImgList
        this.fullViewImgList = data.fullViewImgList
        this.mainRoofImgList = data.mainRoofImgList // 主房屋全景照
        this.sceneEastImgList = data.sceneEastImgList // 住房屋屋顶照
        this.sceneSoutheastImgList = data.sceneSoutheastImgList // 现场东南
        this.sceneSouthImgList = data.sceneSouthImgList // 现场南
        this.sceneSouthwestImgList = data.sceneSouthwestImgList // 现场西南
        this.sceneWestImgList = data.sceneWestImgList // 现场西
        this.sceneSouthfacadeImgList = data.sceneSouthfacadeImgList // 现场南立面
        this.sceneNorthfacadeImgList = data.sceneNorthfacadeImgList // 现场北立面
        this.sceneInternalImgList = data.sceneInternalImgList // 现场内部结构
        this.sceneVideoList = data.sceneVideoList// 现场视屏
        this.azimuthImgList = data.azimuthImgList // 方位角
        this.installImgList = data.installImgList // 逆变器
        this.explorationObj = data.location
      } else {
        this.$message.error(res.data.message)
      }
    },
    async saveBusinessInfo() {
      let data = { ...this.BusinessInfoDate }
      data.businessLicense = this.businessLicenseList2[0] ? this.businessLicenseList2[0].businessLicense : ''
      data.corporateAccount = this.corporateAccountList2[0] ? this.corporateAccountList2[0].corporateAccount : ''
      data.organLicense = this.organLicenseList[0] ? this.organLicenseList[0].organLicense : ''
      data.bwApply = this.bwApplyList[0] ? this.bwApplyList[0].bwApply : ''
      data.propertyCertificate = this.propertyCertificateList[0] ? this.propertyCertificateList[0].propertyCertificate : ''
      data.installationStatement = this.installationStatementList[0] ? this.installationStatementList[0].installationStatement : ''
      data.titleCommitment = this.titleCommitmentList[0] ? this.titleCommitmentList[0].titleCommitment : ''
      let index = this.companyOptions.findIndex(
        (item) => item.agentCode == data.agentCode
      );
      data.customerCode = this.customerCode
      if (index != -1) {
        data.agentName = this.companyOptions[index].organName
      }
      let res = await this.$http.post("businessInfo/save", data);
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        this.drawer = false;
        this.getTable();
        this.getCustomBasicInfo();
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 获取商务资料
    async getBusinessInfo(customerCode) {
      let res = await this.$http.post('/businessInfo/queryOne', { customerCode })
      if (res.data.code == 200) {
        this.BusinessInfoDate = res.data.data
        this.businessLicenseList2 = []
        this.corporateAccountList2 = []
        this.organLicenseList = []
        this.bwApplyList = []
        this.propertyCertificateList = []
        this.installationStatementList = []
        this.titleCommitmentList = []
        res.data.data.businessLicense ? this.businessLicenseList2.push({ explorateImgUrl: res.data.data.certificateImg1 }) : '' // 出租方营业执照
        res.data.data.corporateAccount ? this.corporateAccountList2.push({ explorateImgUrl: res.data.data.certificateImg2 }) : '' // 对公账号信息
        res.data.data.organLicense ? this.organLicenseList.push({ explorateImgUrl: res.data.data.bankcardImg1 }) : '' // 附属机构（营业执照）
        res.data.data.bwApply ? this.bwApplyList.push({ explorateImgUrl: res.data.data.corporateAccount }) : '' // 并网申请表
        res.data.data.propertyCertificate ? this.propertyCertificateList.push({ explorateImgUrl: res.data.data.businessLicense }) : '' // 房屋产权证明
        res.data.data.installationStatement ? this.installationStatementList.push({ explorateImgUrl: res.data.data.corporateAccount }) : '' // 晴天阁特殊安装声明
        res.data.data.titleCommitment ? this.titleCommitmentList.push({ explorateImgUrl: res.data.data.businessLicense }) : '' // 产权承诺书
      } else {
        this.$message.error(res.data.message)
      }
    },
    // 获取审核通过数据
    getAuditRecord(code) {
      this.$http.post("houseExplorateExamineNode/queryNodeDetail", {
        customerCode: code
      }).then(res => {
        this.rightdata = res.data.data
        // 使步骤条倒序
        // this.$nextTick(() => {
        //   var x = document.querySelectorAll(".teps .el-step__icon-inner")
        //   x.forEach((item, index) => {
        //     item.innerHTML = x.length - index
        //   })
        // })

      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    changeAdvanced() {
      this.$set(this.customerInfoForm.baseInfo, 'orderSchedule', this.followRecordForm.followType)
    },
    changeSchedule() {
      this.$set(this.followRecordForm, 'followType', this.customerInfoForm.baseInfo.orderSchedule)
    },
    async delBtn(id) {
      let res = await this.$http.post('houseCustomerFollowRecord/delete', { id })
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
        });
        this.getAttach(this.customerCode)
      } else {
        this.$message.error(res.data.message)
      }
    },
    getColor(str) {
      var className = 'btn1'
      this.setColorByStatus.forEach(item => {
        if (item.status == str) {
          className = className + ' ' + item.className
        }
      })
      return className
    },
    showAttach(list) {
      this.currentFile = list.map(item => item.fileUrl)
      this.dialogVisibleAttachView = true
    },
    async getAttach(customerCode) {
      let res = await this.$http.post('houseCustomerFollowRecord/queryListByCustomerCode', { customerCode })
      if (res.data.code == 200) {
        this.attachLogList = res.data.data
      } else {
        this.$message.error(res.data.message)
      }
    },
    handleRemoveAttach(res, file) {
      let index = this.followRecordForm.followAttachmentList.findIndex(item => item.url == res.response.data.url)
      if (index != -1) {
        this.followRecordForm.followAttachmentList.splice(index, 1)
      }
    },
    handleAttachSuccess(res, file) {
      if (res.code == 200) {
        this.followRecordForm.followAttachmentList.push({ name: file.name, url: res.data.url })
      } else {
        this.$message.error(res.message)
      }
    },
    uploadAttachmentList() {
      this.dialogVisibleAttach = true
    },
    async createFollowRecord() {
      let data = JSON.parse(JSON.stringify(this.followRecordForm))
      let arr = data.followAttachmentList.map(item => { return { fileName: item.name, fileUrl: item.url } })
      data.followAttachmentList = arr
      let res = await this.$http.post('houseCustomerFollowRecord/createFollowRecord', data)
      if (res.data.code == 200) {
        // 刷新数据
        this.followRecordForm.followType = ""// 跟进类型
        this.followRecordForm.followRemark = ""// 跟进备注
        this.followRecordForm.followAttachmentList = []
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        this.getAttach(this.attachCode)
      } else {
        this.$message.error(res.data.message)
      }
    },
    remamberValue() {
      if (this.queryInfo.condition.agentCode) {
        localStorage.setItem(
          "customerListQueryKey",
          this.queryInfo.condition.agentCode
        );
      }
    },
    handleRowClickWl(row) {
      let flag = this.selectWl.some(
        (item) => item.productCode == row.productCode
      );
      this.$refs.tableWl.toggleRowSelection(row, !flag);
    },
    //获取并网信息
    getOnGridInfo() {
      this.$http
        .post("/houseCustomerBw/queryOne", {
          customerCode: this.customerCode,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.onGridInfo = res.data.data;
            let arr = ['fydht', 'nbqzcyx', 'fddbzp', 'fdhhpz', 'sbyjqrd']
            this.onGridInfo.fydht = res.data.data.fydht.split(",");
            this.onGridInfo.nbqzcyx = res.data.data.nbqzcyx.split(",");
            this.onGridInfo.fddbzp = res.data.data.fddbzp.split(",");
            this.onGridInfo.fdhhpz = res.data.data.fdhhpz.split(",");
            this.onGridInfo.sbyjqrd = res.data.data.sbyjqrd.split(",");
            arr.forEach(item => {
              if (this.onGridInfo[item].length == 1 && this.onGridInfo[item][0] == '') {
                this.onGridInfo[item] = []
              }
            })
          }
        });
    },
    toShowImg(url) {
      this.imgVisible = true;
      this.dialogImageUrl = url;
    },
    async saveWl() {
      let arr = JSON.parse(JSON.stringify(this.tableWlInfoData));
      arr.forEach((item) => {
        item.unionName = item.brand + item.specs;
      });
      let data = {
        customerCode: this.currRowObj.customerCode,
        customerName: this.currRowObj.customerName,
        comboList: arr,
      };
      let res = await this.$http.post("houseCustomerCombo/addList", data);
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        this.getTable();
        this.tableWlInfoData = [];
        this.dialogVisibleBaoZhuangStep2 = false;
      } else {
        this.$message.error(res.data.message);
      }
    },
    showStep2(row) {
      let { customerCode, customerName, id, mobile } = row;
      this.currRowObj.customerCode = customerCode;
      this.currRowObj.customerName = customerName;
      this.currRowObj.mobile = mobile;
      this.currRowObj.id = id;
      this.dialogVisibleBaoZhuangStep2 = true;
      this.baoZhuangBtn(); //回显
    },
    wlSelectedBtn() {
      let cloneArr = JSON.parse(JSON.stringify(this.selectWl));
      let arr = cloneArr.map((item) => {
        return {
          productName: item.productName,
          productCode: item.productCode,
          specs: item.specs,
          brand: item.brand,
          num: "",
          unit: item.unit,
          unitPrice: item.price,
          planReachDate: "",
          remark: "",
          productTypeName: item.productTypeName,
          productTypeCode: item.productTypeCode,
          power: item.power,
        };
      });
      this.tableWlInfoData = arr;
      this.dialogVisibleWL = false;
    },
    handleSelectionChangeWl(v) {
      this.selectWl = v;
    },
    async showWl() {
      this.dialogVisibleWL = true;
      this.dialogInnerWlLoading = true;
      let res = await this.$http.post("houseAgentProduct/queryList", {
        agentCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .agentCode,
      });
      this.dialogInnerWlLoading = false;
      if (res.data.code == 200) {
        this.tableWl = res.data.data;
        let arr = [];
        this.tableWlInfoData.forEach((item) => {
          this.tableWl.forEach((i) => {
            if (item.productCode == i.productCode) {
              arr.push(i);
            }
          });
        });
        setTimeout(() => {
          arr.forEach((item) => {
            this.$refs.tableWl.toggleRowSelection(item);
          }, 0);
        });
      } else {
        this.$message.error(res.data.message);
      }
    },
    async saveStep1() {
      let data = JSON.parse(JSON.stringify(this.baoZhuangForm));
      for (let k in data) {
        let arr = data[k].map((item) => {
          return item.url;
        });
        data[k] = arr.join(",");
      }
      if (this.queryOneId) {
        data.id = this.queryOneId;
      }
      data.customerCode = this.currRowObj.customerCode;
      data.customerName = this.currRowObj.customerName;
      this.baoZhuangLoading = true;
      let res = await this.$http.post("houseCustomerElectricityImg/save", data);
      this.baoZhuangLoading = false;
      if (res.data.code == 200) {
        this.getTable();
        this.dialogVisibleBaoZhuang = false;
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
      } else {
        this.$message.error(res.data.message);
      }
    },
    async baoZhuangBtn() {
      let res = await this.$http.post("houseCustomerCombo/queryList", {
        customerCode: this.currRowObj.customerCode,
      });
      if (res.data.code == 200) {
        let arr = res.data.data.map((item) => {
          let {
            productName,
            productTypeCode,
            productTypeName,
            productCode,
            specs,
            brand,
            num,
            unit,
            unitPrice,
            planReachDate,
            remark,
            power,
          } = item;
          return {
            productName,
            productTypeName,
            productTypeCode,
            productCode,
            specs,
            brand,
            num,
            unit,
            unitPrice,
            planReachDate,
            remark,
            power,
          };
        });
        this.tableWlInfoData = arr;
      } else {
        this.$message.error(res.data.message);
      }
    },
    async baoZhuang(row) {
      let { customerCode, customerName, id, mobile } = row;
      this.currRowObj.customerCode = customerCode;
      this.currRowObj.customerName = customerName;
      this.currRowObj.mobile = mobile;
      this.currRowObj.id = id;
      this.dialogVisibleBaoZhuang = true;
      let res = await this.$http.post("houseCustomerElectricityImg/queryOne", {
        customerCode,
      });
      if (res.data.code == 200) {
        if (res.data.data) {
          this.queryOneId = res.data.data.id;
          for (let k in this.baoZhuangForm) {
            if (res.data.data[k]) {
              let arr = res.data.data[k].split(",").map((item) => {
                return { name: item, url: item };
              });
              this.baoZhuangForm[k] = arr;
            } else {
              this.baoZhuangForm[k] = [];
            }
          }
        } else {
          this.queryOneId = null;
          for (let i in this.baoZhuangForm) {
            this.baoZhuangForm[i] = [];
          }
        }
      } else {
        this.$message.error(res.data.message);
      }
    },
    async bingWangBtn() {
      let fydht = this.bingWangForm.fydht.map((item) => {
        return item.url;
      });
      let nbqzcyx = this.bingWangForm.nbqzcyx.map((item) => {
        return item.url;
      });
      let fddbzp = this.bingWangForm.fddbzp.map((item) => {
        return item.url;
      });
      let fdhhpz = this.bingWangForm.fdhhpz.map((item) => {
        return item.url;
      });
      let sbyjqrd = this.bingWangForm.sbyjqrd.map((item) => {
        return item.url;
      });
      let data = {
        id: this.bingWangForm.id,
        fydht: fydht.join(","),
        nbqzcyx: nbqzcyx.join(","),
        fddbzp: fddbzp.join(","),
        fdhhpz: fdhhpz.join(","),
        sbyjqrd: sbyjqrd.join(","),
        contractDate: this.bingWangForm.contractDate,
        customerName: this.currRowObj.customerName,
        customerCode: this.currRowObj.customerCode,
        //  createName:JSON.parse('userInfo').userInfo.name,
        // createCode:JSON.parse('userInfo').userInfo.userCode,
        // updateName:JSON.parse('userInfo').userInfo.name,
        // updateCode:JSON.parse('userInfo').userInfo.userCode
      };

      this.bingWangLoading = true;
      let res = await this.$http.post("houseCustomerBw/addBw", data);
      this.bingWangLoading = false;
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        this.getTable();
        this.dialogVisibleBingWang = false;
      } else {
        this.$message.error(res.message);
      }
    },
    handleSuccessBZ(res, file, type) {
      if (res.code == 200) {
        this.baoZhuangForm[type].push({
          name: res.data.url,
          url: res.data.url,
        });
      } else {
        this.$message.error(res.message);
      }
    },
    handleRemoveBZ(res, file, type) {
      let index = this.baoZhuangForm[type].findIndex(
        (item) => item.url == res.url
      );
      if (index != -1) {
        this.baoZhuangForm[type].splice(index, 1);
      }
    },
    handleSuccess(res, file, type) {
      if (res.code == 200) {
        this.bingWangForm[type].push({ name: res.data.url, url: res.data.url });
      } else {
        this.$message.error(res.message);
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    handleRemove(res, file, type) {
      let index = this.bingWangForm[type].findIndex(
        (item) => item.url == res.url
      );
      if (index != -1) {
        this.bingWangForm[type].splice(index, 1);
      }
    },
    async bingWang(row) {
      let { customerCode, customerName, id, mobile } = row;
      this.currRowObj.customerCode = customerCode;
      this.currRowObj.customerName = customerName;
      this.currRowObj.mobile = mobile;
      for (let k in this.bingWangForm) {
        if (Array.isArray(this.bingWangForm[k])) {
          this.bingWangForm[k] = [];
        }
      }
      this.dialogVisibleBingWang = true;
      let res = await this.$http.post("houseCustomerBw/queryBw", {
        customerCode,
      });
      if (res.data.code == 200) {
        let { contractDate, id, fydht, nbqzcyx, fddbzp, fdhhpz, sbyjqrd } =
          res.data.data;
        this.bingWangForm.id = id;
        let fydhtList = fydht.split(",").map((item) => {
          return { name: item, url: item };
        });
        let nbqzcyxList = nbqzcyx.split(",").map((item) => {
          return { name: item, url: item };
        });
        let fddbzpList = fddbzp.split(",").map((item) => {
          return { name: item, url: item };
        });
        let fdhhpzList = fdhhpz.split(",").map((item) => {
          return { name: item, url: item };
        });
        let sbyjqrdList = sbyjqrd.split(",").map((item) => {
          return { name: item, url: item };
        });
        this.bingWangForm.fydht = fydhtList;
        this.bingWangForm.nbqzcyx = nbqzcyxList;
        this.bingWangForm.fddbzp = fddbzpList;
        this.bingWangForm.fdhhpz = fdhhpzList;
        this.bingWangForm.sbyjqrd = sbyjqrdList;
        this.bingWangForm.contractDate = contractDate;
        if (
          this.bingWangForm.fydht.length == 1 &&
          this.bingWangForm.fydht[0].url == ""
        ) {
          this.bingWangForm.fydht = [];
        }
        if (
          this.bingWangForm.nbqzcyx.length == 1 &&
          this.bingWangForm.nbqzcyx[0].url == ""
        ) {
          this.bingWangForm.nbqzcyx = [];
        }
        if (
          this.bingWangForm.fddbzp.length == 1 &&
          this.bingWangForm.fddbzp[0].url == ""
        ) {
          this.bingWangForm.fddbzp = [];
        }
        if (
          this.bingWangForm.fdhhpz.length == 1 &&
          this.bingWangForm.fdhhpz[0].url == ""
        ) {
          this.bingWangForm.fdhhpz = [];
        }
        if (
          this.bingWangForm.sbyjqrd.length == 1 &&
          this.bingWangForm.sbyjqrd[0].url == ""
        ) {
          this.bingWangForm.sbyjqrd = [];
        }
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getTable() {
      this.tableLoading = true;
      let data = JSON.parse(JSON.stringify(this.queryInfo));
      // data.condition.userCodeList = JSON.parse(
      //   localStorage.getItem("userInfo")
      // ).userInfo.userCodeList;
      // data.condition.loginName = JSON.parse(
      //   localStorage.getItem("userInfo")
      // ).userInfo.loginName;
      // data.dataStatus = '1'
      let res = await this.$http.post("houseCustomer/listPlatform", data);
      this.tableLoading = false;
      if (res.data.code == 200) {
        this.total = res.data.data.count;
        this.tableData = res.data.data.data;
      }
    },
    async saveForm() {
      let arr = ["", this.form1, this.form2, this.form3];
      let data = JSON.parse(JSON.stringify(arr[this.customertype]));
      if (!this.isFirstObj) {
        this.$message.info("请选择归属公司");
        return;
      }
      data.agentCode = this.isFirstObj.organCode;
      data.agentName = this.isFirstObj.organName;
      data.customertype = this.customertype;
      data.createName = JSON.parse(
        localStorage.getItem("userInfo")
      ).userInfo.name;
      data.createCode = JSON.parse(
        localStorage.getItem("userInfo")
      ).userInfo.userCode;

      this.formLoading = true;
      let res = await this.$http.post("houseCustomer/addCustomer", data);
      this.formLoading = false;
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        this.getTable();
        this.dialogVisible = false;
        this.resetForms();
      } else {
        this.$message.error(res.data.message);
      }
    },
    resetForms() {
      for (let k in this.form1) {
        this.form1[k] = "";
      }
      for (let k in this.form2) {
        this.form2[k] = "";
      }
      for (let k in this.form3) {
        this.form3[k] = "";
      }
      this.form1.sex = 1;
      this.form2.source = 1;
      this.form3.source = 1;
    },
    showDetail(row) {
      this.drawer = true;
      let { customerCode, customerNumber, customerName } = row
      this.attachCode = customerCode
      this.customerCode = customerCode;
      this.followRecordForm.customerCode = customerCode
      this.followRecordForm.customerNumber = customerNumber
      this.followRecordForm.customerName = customerName
      this.followRecordForm.followAttachmentList = []
      this.getCustomBasicInfo();
      this.getOnGridInfo();
      this.getAttach(customerCode)
      this.getExploration(customerCode)
      this.getBusinessInfo(customerCode)
      this.getLogList(customerCode)
      this.getAuditRecord(customerCode)
    },
    addCustomer() {
      this.labelType = "add";
      this.isFirstObj = ''
      this.dialogVisible = true;
    },
    handleClick(activeName) {
      if (activeName == 'fifth') {
        this.$http.post('houseContract/signContract', {
          customerCode: this.customerInfoForm.baseInfo.customerCode
        }).then(res => {
          this.signdetail = res.data.data
        })
      }
    },
    handleSizeChange(v) {
      this.queryInfo.pageSize = v;
      this.getTable();
    },
    handleCurrentChange(v) {
      this.queryInfo.currPage = v;
      this.getTable();
    },
    bzBandleClick() { },
    async getCompanyList() {
      let res = await this.$http.post("/houseOrgan/getCompanyList", {
        agentCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .agentCode,
      });
      if (res.data.code == 200) {
        this.companyOptions = res.data.data;
        let agentCode = JSON.parse(localStorage.getItem("userInfo")).userInfo
          .agentCode;
        let index = this.companyOptions.findIndex(
          (item) => item.agentCode == agentCode
        );
        // if (index >= 0) {
        //   this.isFirstObj = this.companyOptions[index];
        // }
      } else {
        this.$message.error(res.data.message);
      }
    },
    resetCondition() {
      for (let k in this.queryInfo.condition) {
        this.queryInfo.condition[k] = "";
      }
      this.getTable();
    },
    setForm1Sex() {
      let n = Number(this.form1.certificateNo);
      if (!isNaN(n)) {
        // 是数字
        if (String(n).slice(-2, -1) % 2 == 1) {
          // 奇数
          this.form1.sex = 1;
        } else if (String(n).slice(-2, -1) % 2 == 0) {
          // 偶数
          this.form1.sex = 2;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-list__item {
  transition: none !important;
}

/deep/ .noscollBar::-webkit-scrollbar {
  width: 0;
}

/deep/ .uploadDialog {
  .el-dialog__body {
    .el-upload__tip {
      margin-top: 4px;
      line-height: 1;
    }

    .el-form-item {
      display: flex;
      flex-direction: column;

      .el-form-item__content {
        margin-top: 6px;
      }

      .el-form-item__label {
        text-align: left;
        font-weight: 600;
      }

      .el-form-item__label::after {
        content: ":";
        padding-left: 2px;
      }
    }
  }
}

/deep/ .body70 {
  .el-dialog__body {
    height: 70vh;
    overflow: auto;
  }
}

/deep/ .dialogBingWang {
  position: relative;

  .dialog-foot-btn {
    display: flex;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;

    .el-button {
      padding: 0 40px;
      height: 36px;
    }
  }
}

/deep/.el-upload-dragger {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
}

/deep/.el-upload-list--picture-card .el-upload-list__item {
  height: 100px;
  width: 100px;
}

/deep/ .diyUpload {
  .el-upload--picture-card {
    height: 100px;
    width: 90%;
    line-height: 100px;
  }
}

/deep/ .diyHeight {
  .el-upload--picture-card {
    height: 100px;
    width: 100px;
    line-height: 100px;
  }
}

/deep/ .buttonIndex1 {
  form {
    margin-top: 10px;
  }
}

.small-height-input {
  .el-input__inner {
    height: 27px !important;
    line-height: 27px !important;
  }
}

/deep/.addUserDialog {
  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 15px;
    position: relative;

    .dialog-foot-btn {
      // position: absolute;
      // bottom: 20px;
      // left: 50%;
      // transform: translateX(-50%);
      display: flex;
      justify-content: center;
      margin-top: 10px;

      .el-button {
        padding: 0 40px;
        height: 36px;
      }
    }
  }
}

.dialog-main {
  box-sizing: border-box;
  padding: 16px 28px;
  height: 70vh;
  overflow: auto;
}

.block-title {
  position: relative;
  width: fit-content;
  color: #333;
  font-size: 13px;
  font-weight: 600;
}

.block-title::before {
  content: "";
  display: block;
  width: 3px;
  height: 100%;
  position: absolute;
  left: -10px;
  background-color: #02a7f0;
}

.dialog-threeBtn {
  margin-top: 8px;
  box-sizing: border-box;
  padding-left: 60px;

  .el-button {
    font-size: 12px;
    height: 26px;
    color: #02a7f0;
    border-color: #02a7f0;
  }

  .active-btn {
    background-color: #02a7f0;
    color: #fff;
  }
}

/deep/.currPage {
  .v-modal {
    top: 60px;
  }

  .el-drawer.rtl {
    top: 60px;
  }
}

.customer {
  padding-top: 7px;
  height: 100%;
  background-color: #f5f7f9;

  .tip {
    padding: 0 16px;

    .el-col {
      border-radius: 4px;
    }

    .grid-content {
      border-radius: 4px;
      background-color: #fff;
      position: relative;
      padding-left: 10px;
      padding-top: 10px;
      padding-right: 10px;
      box-sizing: border-box;

      i {
        border-radius: 50%;
        color: white;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
      }
    }

    .row-bg {
      padding: 10px 0;
    }

    span {
      font-size: 15px;
      font-weight: 600;
    }
  }

  .v-modal {
    top: 60px;
  }

  .el-drawer.rtl {
    top: 60px;
  }

  .drawer-main {
    flex-direction: column;

    .el-card__header {
      padding: 7px 20px;
    }

    .drawer-head {
      position: relative;
      font-size: 16px;
      font-weight: 600;
      padding: 6px 16px;
      color: #333;
      border: 1px solid #dcdfe6;
    }

    .drawer-bottom-left {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 70%;

      .el-button {
        height: 26px;
      }

      /deep/.top-info {
        font-size: 14px;
        box-sizing: border-box;
        padding: 20px;
        padding-bottom: 0;
        padding-top: 7px;
        color: #333;

        .el-row:nth-last-child(2) {
          margin-bottom: 7px;
        }

        .bottom-tab {
          margin-top: 0px;

          .el-tabs__nav-wrap::after {
            background-color: transparent;
          }

          .el-tabs__header {
            margin: 0 0 3px;
          }

          .el-tabs__item {
            height: 34px;
          }
        }

        .top-row {
          margin-bottom: 11px;

          .btn-group {
            .el-button {
              font-size: 12px;
            }

            .active-btn {
              background: #1049da;
              color: #fff;
            }
          }

          .user-label {
            cursor: pointer;

            i {
              color: #2e64ee;
            }

            span {
              margin-left: 6px;
              color: #2e64ee;
            }
          }

          .label {
            font-weight: 600;
          }

          .label::after {
            content: ":";
            padding: 0 16px 0 2px;
          }
        }
      }

      /deep/.bottom-info {
        flex-grow: 1;
        background-color: rgb(242, 242, 242);
        padding: 10px;
        box-sizing: border-box;
        height: calc(100vh - 207px);
        overflow-y: scroll;

        .detail-card {
          // padding: 7px 20px;
          padding: 0;
          padding-bottom: 0;
        }

        .el-card__header {
          padding: 7px 20px;
        }

        .bottom-info-box {
          height: 100%;
          border-radius: 4px;
          background-color: #fff;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12),
            0 0 6px rgba(0, 0, 0, 0.04);
        }

        .qy_tit {
          display: flex;
        }

        .qy_Box {
          border: 1px soild #333;
          border-radius: 10px;
          border: 1px solid #d7d7d7;
          background-color: #ffffff;
          font-size: 16px;
          margin-top: 20px;
          margin-left: 16px;
          padding-bottom: 20px;

          .qy_Boxli {
            display: flex;
            margin: 20px 0;

            .lis {
              flex: 1;
              display: flex;

              .lis_one {
                flex: 1;
                color: #7f7f7f;
                padding-left: 15%;
              }

              .lis_two {
                flex: 1;
                color: #333333;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .send {
            width: 140px;
            height: 40px;
            padding: 2px 2px 2px 2px;
            border-radius: 5px;
            background-color: #2e64ee;
            text-align: center;
            line-height: 40px;
            color: #fff;
            margin-left: 70%;
          }
        }
      }
    }

    .drawer-bottom-right {
      width: 30%;
      box-sizing: border-box;
      border-left: 1px solid #dcdfe6;

      .team-user {
        box-sizing: border-box;
        padding: 12px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #333;
        border-bottom: 1px solid #dcdfe6;

        .team-title {
          font-size: 15px;
          font-weight: 600;
        }

        .team-bottom {
          font-size: 14px;

          .team-label::after {
            content: ":";
            padding-left: 2px;
          }

          .team-value {
            margin-left: 12px;
          }
        }
      }

      .two-btns {
        margin-top: -25px;
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
        padding: 12px;
      }

      .logList {
        margin-top: 0px;
        box-sizing: border-box;
        padding: 12px;

        .log-title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          >span {
            font-size: 13px;
            color: #7f7f7f;
          }
        }
      }
    }
  }

  .topButton {
    padding: 10px 16px;
    background-color: #fff;
  }

  .box-padding {
    padding: 0 16px;
  }

  .center-box {
    .form-card {
      //   margin-top: 8px;
      border-radius: 10px;

      .el-card__body {
        padding: 20px 0;

        .el-form-item {
          .el-form-item__label {
            &::after {
              content: ":";
              padding-left: 2px;
            }
          }
        }
      }
    }
  }

  .bottom-box {
    margin-top: 12px;

    .el-card {
      border-radius: 10px;
    }

    .center-head {
      display: flex;
      justify-content: space-between;

      .small-padding {
        // padding: 0 10px;
      }

      .right {
        .small-padding {
          color: #1049da;
          background-color: #edf1f9;
        }
      }
    }
  }
}

.basicInfoBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  font-size: 14px;

  .basicInfoItem {
    display: flex;
    align-items: center;
    gap: 10px;

    .basicTitleItem {
      width: 80px;
      align-items: center;
      margin-top: -40px;
      display: flex;
      font-size: 14px;
    }

  }

  .basicInfoBox1 {
    .basicInfoItem {
      display: flex;
      align-items: center;
      gap: 10px;

      .basicTitleItem {
        width: 80px;
        align-items: center;
        margin-top: -40px;
        display: flex;
        font-size: 14px;
      }

    }
  }

  .basicInfoItem>div:first-child {
    width: 150px;
    text-align: right;
  }

  .basicInfoItem>div:last-child {
    // max-width: 180px;
    flex: 1;
    word-wrap: break-word;
  }
}

.onGridInfoBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  .onGridInfoItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;

    .onGridImgBox {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .onGridImgItem {
        width: 100px;
        height: 100px;
      }
    }
  }
}

.bottom-tab {

  // margin-top: 38px;
  /deep/.el-tabs__nav-wrap::after {
    background-color: transparent;
  }

  .el-tabs__header {
    margin: 0 0 3px;
  }

  .el-tabs__item {
    height: 34px;
  }
}

/deep/ .smallpadding {
  transform: translateY(6px);

  .el-card__body {
    padding: 7px 10px 0 10px;

    .el-form-item {
      margin-bottom: 7px;
    }
  }
}

/deep/ .diy-padding {
  .el-card__body {
    padding: 12px;
  }
}

// /deep/ .table-head-row{
//     line-height: 26px;
// }
// /deep/ .el-table--small td, .el-table--small th{
//     padding: 0;
// }
// 表格高度不一致，将高度撑开
/deep/ .el-table__fixed-right {
  height: 100% !important;
}

/deep/ .formSize {
  .el-input--small .el-input__inner {
    line-height: 32px !important;
    height: 32px !important;
    border-color: darken(#dcdfe6, 12%);
  }

  .el-form-item__label {
    font-size: 13px;
    padding: 0 6px 0 0;
  }

  .el-form-item__label::after {
    content: ":";
    padding-left: 1px;
  }
}

// /deep/ .el-table__body-wrapper{
//  z-index: 2004;
// }
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #02a7f0;
}

/deep/ .el-table__body tr.hover-row>td {
  background-color: #f59a23 !important;
  cursor: pointer;
}

/deep/ .el-table__body tr td {
  width: fit-content !important;
}

/deep/ .padding7 {
  padding: 7px;
}

/deep/ .margin7 {
  margin-top: 7px;
}

/deep/.wlStyle {
  .el-dialog__body {
    padding-top: 7px;
  }
}

/deep/ .headpadding {
  .el-table {
    margin-top: 12px;
  }

  .el-dialog__header {
    padding: 12px 20px 0;
  }
}

/deep/ .head0 {
  .el-dialog__header {
    padding-bottom: 0;
  }
}

/deep/.flexrow {
  display: flex;
  flex-wrap: wrap;
}

/deep/ .overflowTip {
  >div {
    padding: 0;
  }
}

/deep/ .row-padding {
  >div {
    padding: 0;
  }
}

/deep/ .el-table .cell {
  padding: 1px;
}

/deep/.fontSize-table {
  .el-button {
    height: 20px !important;
    padding: 1px 6px !important;
  }

  .el-tag {
    height: 16px;
    line-height: 16px;
  }

  font-size: 14px;

  .cell {
    line-height: 16px;
  }
}

/deep/ .cellNopadding {
  .cell {
    padding-left: 0 !important;
  }
}

/deep/ .btn1 {
  margin-left: 0;
  background: #fdebd3;
  border-color: #f59a23;
  color: #f59a23;
  border-radius: 4px;
}

/deep/ .btn2,
.btn3 {
  margin-left: 3px;
  background: #ccedfc;
  border-color: #02a7f0;
  color: #02a7f0;
  border-radius: 4px;
}

/deep/ .btn3 {
  background: #eafdcd;
  border-color: #63a103;
  color: #63a103;
}

/deep/ .drawer-close {
  cursor: pointer;
  position: absolute;
  padding: 6px 12px;
  top: 4px;
  right: 8px;
}

/deep/ .drawer-close:hover {
  color: #02a7f0;
}

.draw-record {
  padding: 12px;
  box-sizing: border-box;

  .history-record {
    font-size: 16px;
    padding: 0 0 6px;
    font-weight: 600;
    border-bottom: 1px solid #eafdcd;
  }

  /deep/.step-record {
    height: calc(100vh - 100px);
    overflow: auto;
    box-sizing: border-box;
    padding-top: 20px;
    box-sizing: border-box;
    padding-left: 16px;

    .el-step__main {
      // transform: translateY(-10px);
    }

    // .el-step__title {
    //   line-height: 1;

    //   .step-time {
    //     color: #62B87D;
    //     height: 14px;
    //     line-height: 14px;
    //     // position: relative;
    //   }
    // }

    .step-text {
      .step-text-title {
        color: #333333;
        transform: translateX(-7px);
        font-size: 16px;
        font-weight: 500;
      }

      .step-text-desc {
        color: #333333;
        font-size: 13px;
      }
    }
  }
}

/deep/ .attach>div {
  height: 100px;
  width: 100px;
  line-height: 100px;
}

/deep/ .padding10 {
  .el-dialog__body {
    padding: 10px 20px;

  }
}

/deep/ .box-card {
  .el-card__body {
    padding: 10px;
  }
}

/deep/ .set-1 {
  border-color: #79A377;
  background-color: #DFF0D8;
  color: #658A64;
}

/deep/ .set-2 {
  border-color: #81D3F8;
  background-color: #81D3F8;
  opacity: 0.45;
  color: #02A7F0;
}

/deep/ .set-3 {
  border-color: #FACD91;
  background-color: #FCF8E3;
  color: #7F5F2A;
}

/deep/ .set-4 {
  border-color: #EC808D;
  background-color: #F2DFDF;
  color: #AE4F4E;
}

/deep/ .set-5 {
  border-color: #8400FF;
  //  background-color: #C280FF;
  background-color: rgba(194, 128, 255, 0.22);
  color: #8400FF;
}

/deep/ .set-6 {
  border-color: #EC808D;
  background-color: #FFF5EE;
  color: #FF29B0;
  opacity: 0.47;
}

/deep/ .set-7 {
  border-color: #C8CD00;
  background-color: #FFFF80;
  color: #C8CD00;
}

/deep/ .set-8 {
  border-color: #D9001B;
  background-color: #D9001B;
  color: #D9001B;
}

/deep/ .set-9 {
  border-color: #5CB85C;
  background-color: #5CB85C;
  color: #FFFFFF;
}

/deep/ .delBtn {
  position: absolute;
  z-index: 2;
  right: 20px;

  i {
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }
}

/deep/ .el-upload--picture-card {
  width: 50px;
  height: 50px;
  line-height: 60px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 50px;
  height: 50px;
}

.imgBox {
  width: 100px;
  height: 100px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.imgBox:hover .img-icon {
  display: flex;
}

.img-icon {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar-uploader {
  width: 100%;
}

.avatar-uploader ::v-deep .el-upload {
  //background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100px;
  height: 102px;
  flex-wrap: wrap;
  gap: 10px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #c0ccda;
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

/deep/.el-loading-spinner {
  margin-top: -74px;
}

/deep/.el-tabs__item:focus.is-active.is-focus:not(:active) {
  box-shadow: none;
}

.avatar {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 6px;
}

.imgBox {
  width: 100px;
  height: 100px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: #f59a23 !important;
}

::v-deep .el-step__icon.is-text {
  border-color: #66B1FD !important;
  /* 不改变线的颜色，保持默认或者自定义 */
}

::v-deep .el-step__icon-inner {
  color: #66B1FD;
}

.imgText {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
  color: red;
  font-size: 0.8rem;
}
</style>
